export const GET_CABLETV = 'GET_CABLETV'
export const GET_DATA_VARIATIONS = 'GET_DATA_VARIATIONS'
export const GET_NETWORKS = 'GET_NETWORKS'
export const GET_ELECTRICITY_VARIATIONS = 'GET_ELECTRICITY_VARIATIONS'
export const GET_EDUCATION_VARIATIONS = 'GET_EDUCATION_VARIATIONS'
export const VERIFY_PHONENUMBER_SUCCESS = 'VERIFY_PHONENUMBER_SUCCESS'
export const VERIFY_PHONENUMBER_FAIL = 'VERIFY_PHONENUMBER_FAIL'
export const VERIFY_COUPON_FAIL = 'VERIFY_COUPON_FAIL'
export const VERIFY_COUPON_SUCCESS = 'VERIFY_COUPON_SUCCESS'
export const VERIFY_METER_SUCCESS = 'VERIFY_METER_SUCCESS'
export const VERIFY_METER_FAIL = 'VERIFY_METER_FAIL'
export const VERIFY_SMARTCARD_FAIL = 'VERIFY_SMARTCARD_FAIL'
export const VERIFY_SMARTCARD_SUCCESS = 'VERIFY_SMARTCARD_SUCCESS';
export const PURCHASE_AIRTIME_SUCCESS = 'PURCHASE_AIRTIME_SUCCESS';
export const PURCHASE_AIRTIME_FAIL = 'PURCHASE_AIRTIME_FAIL';
export const PURCHASE_DATA_FAIL = 'PURCHASE_DATA_FAIL';
export const PURCHASE_DATA_SUCCESS = 'PURCHASE_DATA_SUCCESS';
export const PURCHASE_CABLE_SUCCESS = 'PURCHASE_CABLE_SUCCESS';
export const PURCHASE_CABLE_FAIL = 'PURCHASE_CABLE_FAIL';
export const PURCHASE_EDUCATION_FAIL = 'PURCHASE_EDUCTION_FAIL';
export const PURCHASE_EDUCATION_SUCCESS = 'PURCHASE_EDUCTION_SUCCESS';
export const PURCHASE_ELECTRICITY_SUCCESS = 'PURCHASE_ELECTRICITY_SUCCESS';
export const PURCHASE_ELECTRICITY_FAIL = 'PURCHASE_ELECTRICITY_FAIL';

