import {GET_DATA_VARIATIONS,GET_CABLETV,GET_EDUCATION_VARIATIONS,GET_ELECTRICITY_VARIATIONS,GET_NETWORKS,VERIFY_PHONENUMBER_SUCCESS,VERIFY_PHONENUMBER_FAIL,VERIFY_COUPON_FAIL,VERIFY_COUPON_SUCCESS,VERIFY_METER_FAIL,VERIFY_METER_SUCCESS, VERIFY_SMARTCARD_FAIL, VERIFY_SMARTCARD_SUCCESS,PURCHASE_AIRTIME_FAIL,PURCHASE_AIRTIME_SUCCESS,PURCHASE_CABLE_FAIL,PURCHASE_CABLE_SUCCESS,PURCHASE_DATA_FAIL,PURCHASE_DATA_SUCCESS,PURCHASE_EDUCATION_FAIL,PURCHASE_EDUCATION_SUCCESS,PURCHASE_ELECTRICITY_FAIL,PURCHASE_ELECTRICITY_SUCCESS} from '../../actions/public/types'

const initialState = {
    data_variations: [],
    networks: [],
    cabletv_variations: [],
    electricity_variations: [],
    education_variations: [],
    server_message: {
        type: '',
        info: ''
    }
    
};


export default ( state = initialState, action ) => {
    switch ( action.type ) {
        case GET_DATA_VARIATIONS:
            return {
                ...state,
                data_variations: action.payload
            };
        
        case GET_CABLETV:
            return {
                ...state,
                cabletv_variations: action.payload
            };
        case GET_EDUCATION_VARIATIONS:
            return {
                ...state,
                education_variations: action.payload
            };
        
        case GET_ELECTRICITY_VARIATIONS:
            return {
                ...state,
                electricity_variations: action.payload
            };
        case GET_NETWORKS:
            return {
                ...state,
                networks: action.payload
            };


        case PURCHASE_EDUCATION_SUCCESS:
        case PURCHASE_EDUCATION_FAIL:
        case PURCHASE_ELECTRICITY_SUCCESS:
        case PURCHASE_ELECTRICITY_FAIL:
        case PURCHASE_CABLE_FAIL:
        case PURCHASE_CABLE_SUCCESS:
        case PURCHASE_DATA_SUCCESS:
        case PURCHASE_DATA_FAIL:
        case PURCHASE_AIRTIME_SUCCESS:
        case PURCHASE_AIRTIME_FAIL:
        case VERIFY_METER_FAIL:
        case VERIFY_METER_SUCCESS:
        case VERIFY_SMARTCARD_FAIL:
        case VERIFY_SMARTCARD_SUCCESS:
        case VERIFY_COUPON_SUCCESS:
        case VERIFY_PHONENUMBER_FAIL:
        case VERIFY_PHONENUMBER_SUCCESS:
        case VERIFY_COUPON_FAIL:
            return {
                ...state,
                server_message: action.payload
            };
            
        
    
        default:
            return state;
    };
};