import React, { useState, useEffect } from 'react';
import './Resetpassword.css';
import './mobile.css';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../../store';
import {
  HANDLE_VERIFY_RESET_TOKEN,
  resetPassword,
} from '../../../actions/Action';

const Resetpassword = ({ errorMessage, match }) => {
  const [type, setType] = useState('password');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();
  const showPassword = (e) => {
    setType('text');
    const faEye = e.currentTarget;
    //Hide Fa Eye
    faEye.style.display = 'none';

    //Show Fa Eye Slash
    const faEyeSlash = e.currentTarget.nextSibling;
    faEyeSlash.style.display = 'block';
  };

  const hidePassword = (e) => {
    setType('password');
    const faEye = e.currentTarget.previousSibling;
    //Show Fa Eye
    faEye.style.display = 'block';

    //Hide Fa Eye Slash
    const faEyeSlash = e.currentTarget;
    faEyeSlash.style.display = 'none';
  };

  //Verify Token
  useEffect(() => {
    let resetToken = match.params.token;
    if (resetToken) {
      let data = {
        token: resetToken,
      };
      setTimeout(() => {
        store.dispatch(HANDLE_VERIFY_RESET_TOKEN(data));
      }, 1000);
    } else {
      //   history.push('/auth/login');
    }
  }, []);

  //Watch Error Message
  useEffect(() => {
    if (errorMessage && errorMessage.type === 'reset_verification_fail') {
      setMsg(errorMessage.msg);
    }
    if (errorMessage && errorMessage.type === 'reset_verification_pass') {
      setShowForm(true);
    }
    if (errorMessage && errorMessage.type === 'reset_done') {
      setMsg(errorMessage.msg);

      setTimeout(() => {
        setShowForm(false);
      }, 2000);
    }
  }, [errorMessage]);

  const resetPasswordNow = () => {
    const length = password.match(/[A-Z0-9a-z]{8,}/g);

    if (!password) {
      return setMsg('Please enter  password.');
    }

    if (!length) {
      return setMsg('Password too short.');
    }

    if (password !== confirmPassword) {
      return setMsg('Passwords not equal');
    }

    let resetToken = match.params.token;

    let data = {
      token: resetToken,
      password,
    };

    store.dispatch(resetPassword(data));
  };

  return (
    <section className='reset-password'>
      <div className='form'>
        {showForm ? (
          <>
            <h1>Reset Password</h1>
            <h3>{msg}</h3>
            <form>
              <div>
                <i className='fa fa-lock' />
                <input
                  autoComplete={false}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='New password'
                  type={type}
                />
                <i className='fa fa-eye eye' onClick={(e) => showPassword(e)} />
                <i
                  className='fa fa-eye-slash eye'
                  onClick={(e) => hidePassword(e)}
                />
              </div>
              <div>
                <i className='fa fa-check' />
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder='Confirm password'
                  type={type}
                />
              </div>
            </form>

            <p onClick={() => resetPasswordNow()}>RESET</p>
          </>
        ) : (
          <p className='Error'>{msg}</p>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = ({ data: { errorMessage } }) => ({
  errorMessage,
});

export default connect(mapStateToProps, null)(Resetpassword);
