import React from 'react'
import './TotalNumberOfTransactions.css'
import './mobile.css'
const TotalNumberOfTransactions = ({Transactions}) => {
    return (
        <div className="total-number-of-transactions">
            <div className="value">
                <p>{ Transactions?Transactions.length:null}</p>
            </div>
            <p>Total Number <br></br>Of Transactions</p>
        </div>
    )
}

export default TotalNumberOfTransactions
