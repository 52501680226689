import Crypto from 'crypto'
export default {
    formatCurrency: ( num ) => {
        return ' ₦' + Number( num.toFixed( 2 ) ).toLocaleString() + ' ';
    },

    currency: ( num ) => {
        return  Number( num.toFixed( 2 ) ).toLocaleString() + ' ';
    },

    showPopUp: () => {
        const pop = document.getElementsByClassName( 'popups' );
        pop[0].classList.add('show-blurry-pop')
    },
    showAdminPopUp: () => {
        const pop = document.getElementsByClassName( 'popups-admin' );
        pop[0].classList.add('show-blurry-pop')
    },

    closePopUp: () => {
        const pop = document.getElementsByClassName( 'popups' );
        pop[0].classList.remove('show-blurry-pop')
    },
    closeAdminPopUp: () => {
        const pop = document.getElementsByClassName( 'popups-admin' );
        pop[0].classList.remove('show-blurry-pop')
    },
    generateId: () => {
        const id = Crypto.randomBytes( 10 ).toString( 'hex' );
        return id;
    }
 
}