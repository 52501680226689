import React, {useState,useEffect} from 'react'
import './Forgotpassword.css';

import store from '../../../store'
import { forgotPassword } from '../../../actions/Action';
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { INITIATE_RESET_FAIL, INITIATE_RESET_SUCCESS } from '../../../actions/types';
const Forgotpassword = ({errorMessage}) => {
    const [ email, setEmail ] = useState( '' );
    const [ msg, setMsg ] = useState( '' );
    const [ showMessage, setShowMessage ] = useState(false)
   
    const history = useHistory();

    const reset = () => {
        const checkEmail =email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        if ( !email || !checkEmail ) {
            return setMsg( 'Invalid Email Address.' );
        }
        let data = {
            email
        }
        
        
            store.dispatch(forgotPassword( data ))
        
    };

    useEffect( () => {
        if ( errorMessage && errorMessage.type === INITIATE_RESET_SUCCESS ) {
            setMsg( `A password reset link has been sent to ${ email }, Please check your spam folder if not found.` );
            setEmail( '' )
            setShowMessage(true)
            setTimeout( () => {
                history.push( '/auth/login' )
            }, 5000 )
        } else if(errorMessage && errorMessage.type === INITIATE_RESET_FAIL){
            setMsg( errorMessage.msg );
            
            
    }
},[errorMessage])
    

    

    
  


    return (
        <section className="forgot-password">
            
            
            <div className="form">
                { !showMessage ? <>
                    <h1>Forgot Password</h1>
                <h3>{ msg } </h3>
                <form>
                    <div>
                        <i className="fa fa-envelope"></i>
                        <input placeholder="Enter your email" value={ email } type="email" onChange={ ( e ) => setEmail( e.target.value ) } />
                    </div>
                </form>
                    <p onClick={ () => reset() }>RESET</p></> : <>
                    
                <h3 className="resetMsg">{msg}</h3></> }
            </div>
            
        </section>
    )
};

const mapStateToProps = ( { data:{errorMessage} } ) => ({
    errorMessage
})

export default connect(mapStateToProps, null)(Forgotpassword)
