import React, { useState,useEffect} from 'react'
import './Cabletv.css';
import './mobile.css';
import Utils from '../../../Utils/Functions'
import store from '../../../../store';
import { connect } from 'react-redux';
import {buyCableTV,userLoad, validateCoupon} from '../../../../actions/Action'
import { VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS } from '../../../../actions/public/types';
import { HandleVerifyCoupon } from '../../../../actions/public';
const Cabletv = ({errorMessage,user,cabletv_variations,server_message}) => {
    const [ plans, setPlans ] = useState([]);
    const [ amount, setAmount ] = useState();
    const [ pin, setPin ] = useState( '' );
    const [ smartCardNumber, setSmartCardNumber] = useState( '' );
    const [ planId, setplanId ] = useState( '' );
    const [msg, setMsg] = useState('')
    const [ tvs, setTvs ] = useState( [] );
    const [ selectedTV, setSelectedTV ] = useState();
    const [ amountToPay, setAmountToPay ] = useState( 0 );
    const [convFee, setConvFee] = useState()
    const [ serviceId, setServiceId ] = useState( '' );
    const [ plan, setPlan ] = useState( '' );
    const [code, setCode] = useState('')

    //Check Error Message
    useEffect( () => {
        if ( errorMessage && errorMessage.type === 'purchase_cabletv_fail' ) {
            setMsg( errorMessage.msg )
            // Utils.closePopUp();
        } else if ( errorMessage && errorMessage.type === 'purchase_cabletv_success' ) {
            setMsg( errorMessage.msg );
            setPin( '' );
            setSmartCardNumber('')
            
            
            // Utils.closePopUp();
        } 
        
        if (server_message.type === VERIFY_COUPON_FAIL ) {
            setMsg( server_message.info )
        } else if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'CABLETV' ) {
            setplanId( server_message.info.meta_data.plan_id );
            setPlan( server_message.info.meta_data.plan )
            setSelectedTV( server_message.info.meta_data.name )
            setServiceId( server_message.info.meta_data.service_id );
            setAmount(server_message.info.meta_data.amount)
            
        } else if (server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'CABLETV') {
            setMsg('Invalid coupon code supplied.')
        }

        return () => console.log('')
    }, [ errorMessage,server_message ] );

    /* */
    useEffect( () => {
        
        setTvs( cabletv_variations )
    
        return () => console.log('')
    }, [ cabletv_variations ] );


    useEffect( () => {
        if ( tvs && tvs.length !== 0 ) {
            setSelectedTV( tvs[ 0 ].name )
            setConvFee(tvs[0].wallet_convinience_fee)
        };

        return () => console.log('')
    },[tvs])

    


    //Update Cable Plans to Show
    useEffect( () => {
        if ( selectedTV ) {
            const tvPlans = tvs.find( d => d.name === selectedTV );
            if ( tvPlans ) {
                setConvFee(tvPlans.wallet_convinience_fee)
                setPlans(tvPlans.plans)
                setServiceId(tvPlans.service_id)
            } else {
                setPlans()
            }
            
        };

        return () => console.log('')
    },[selectedTV])

    //Set Amount
    useEffect( () => {
        if ( plans && plans.length !==0 &&  plans[ 0 ].price) {
            setAmount( Utils.formatCurrency( parseInt( plans[ 0 ].price ) ) );
            setAmountToPay(Utils.formatCurrency( Math.round(plans[0].price) + parseFloat(convFee)))
            setplanId(plans[0].plan_id)
        } else {
            setAmount( '' );
            setplanId('')
        };

        return () => console.log('')
    },[plans]);


    //Update AMount
    const updateAmount = ( e ) => {
        const value = e.target.value;
        const splittedValue = value.split( '-' )
        // console.log(value)
        // console.log(value.split('-'));
        // setplanId( e.target.selectedOptions[ 0 ].id )
        // console.log(e.target.selectedOptions[ 0 ].uniq_name )
        
        let amount = plans.find( plan => plan.id === e.target.selectedOptions[ 0 ].id );
        if (amount  && amount.price ) {
            setAmount( Utils.formatCurrency( parseInt( amount.price ) ) );
            setAmountToPay(Utils.formatCurrency( Math.round(amount.price) + parseFloat(convFee)))
            setplanId( amount.plan_id )
        }

    };

    const orderCableTv = () => {
       
        const space = smartCardNumber.match( /\s/g );
        const non_digit = smartCardNumber.match( /\D/g );
        const alpha = smartCardNumber.match( /[a-zA-Z]/g );
        if ( parseFloat( amount.replace( /₦/g, '' ).replace( /,/g, '' ) ) > parseFloat( user.wallet_balance ) ) {
            return setMsg( 'Insufficient funds.' )
        } else if ( smartCardNumber && planId !== '' && !space && !non_digit && !alpha ) {
            if ( pin !== '' ) {
                setMsg( '' );
                let data = {
                    service_id:serviceId,
                    plan_id:planId,
                    pin,
                    smart_card_number:smartCardNumber,
                    coupon_code:code
                }

                store.dispatch( buyCableTV( data ) )
                
            } else {
                setMsg( 'Invalid Pin' )
            }
        } else {
            setMsg( 'Invalid Decoder Number' )
        }
    };

    const modalLeft = () => {
        return (
            <div className="modal-left">
                <form>
                <h5>{ msg}</h5>
                    
                    <div className="selectect-tv">
                        <label>Select TV</label>
                        <select value={selectedTV} onChange={e=>setSelectedTV(e.target.value)}>
                            { ( tvs.length > 0 ) ? 
                                tvs.map( tv => (
                                    <option key={tv.id}>{tv.name.toUpperCase()}</option>
                                ))
                                : <option>select TV</option> }
                        </select>
                    </div>
                    <div className="select-plans">
                        <label>select plan</label>
                        <select  onChange={e=>updateAmount(e)}  >
                       
                            { ( plans.length > 1 ) ? plans.map( p => (
                                <option id={ p.id } key={ p.id }>{ p.name.toUpperCase() }</option>
                           )):<option>Select Plan</option>}
                        </select>
                    </div>
                    <div>
                        <label>Amount</label>
                        <input disabled={ true } value={ amount}/>
                    </div>
                    <div className="amount">
                        <label>Amount + Convenience Fee({convFee?Utils.formatCurrency(parseFloat(convFee)):null})</label>
                        <input disabled={ true } value={ amountToPay}/>
                    </div>
                    <div>
                        <label>Smart Card Number</label>
                        <input value={smartCardNumber} onChange={(e)=>setSmartCardNumber(e.target.value)} />
                    </div>
                    <div  className="pin">
                        <label>Transaction Pin</label>
                        <input value={pin} onChange={(e)=>setPin(e.target.value)} />
                    </div>
                </form>
                <p onClick={()=>orderCableTv()} >Order Now</p>
            </div>
        )
    };

    const loadCoupon = () => {
        if ( !code ) {
          return  setMsg('Coupon code cannot be blank.')
        }
        const data = {
            coupon_code:code
        }
        setMsg('')
        
        store.dispatch(HandleVerifyCoupon(data))

    }

    const modalRight = () => {
        return (
            <div className="modal-right">
                { plan ? 
                    <>
                         <form>
                <h5>{ msg}</h5>
                    
                    <div className="selectect-tv">
                        <label>TV</label>
                        <input disabled={ true } value={selectedTV}/>
                    </div>
                    <div className="select-plans">
                        <label>Plan</label>
                        <input disabled={ true } value={plan}/>
                    </div>
                    <div>
                        <label>Amount</label>
                        <input disabled={ true } value={ amount}/>
                    </div>
                    <div>
                        <label>Smart Card Number</label>
                        <input value={smartCardNumber} onChange={(e)=>setSmartCardNumber(e.target.value)} />
                    </div>
                    <div  className="pin">
                        <label>Transaction Pin</label>
                        <input value={pin} onChange={(e)=>setPin(e.target.value)} />
                    </div>
                </form>
                <p onClick={()=>orderCableTv()} >Order Now</p>
                    </>
                    : 
                    <>
                         <form>
                    <h5>{ msg}</h5>
                    <div>
                    <div className="coupon-code">
                        <label>Enter coupon code</label>
                        <input onChange={ ( e ) => setCode( e.target.value ) } value={code}/>
                    </div>
                    </div>
                        </form>
                        <p onClick={()=>loadCoupon()} >Load</p>
                    </>
                    }
            </div>
        )
    }

    const handleChangeForm = ( { e, className } ) => {
        const modalRight = document.getElementsByClassName('modal-right')
        const modalLeft = document.getElementsByClassName('modal-left')
        setPlan('')
        setMsg('')
        if ( className === 'right' ) {
            //Remove Active class from left
            e.currentTarget.previousElementSibling.classList.remove('left-active')
            e.currentTarget.classList.add( 'right-active' );
            modalLeft[ 0 ].style.display = 'none';
            modalRight[0].style.display = 'flex'
        }


        if ( className === 'left' ) {
            //Remove Active class from right
            e.currentTarget.nextElementSibling.classList.remove('right-active')
            e.currentTarget.classList.add( 'left-active' );
            modalLeft[ 0 ].style.display = 'flex';
            modalRight[0].style.display = 'none'
        }
    }

    return (
        <section className="cabletv">
            <h3>Cable TV</h3>
            <div className="wrap">
                <div className="header">
                <h4 onClick={(e)=>{handleChangeForm({e,className:'left'})}} className="left left-active">Use wallet</h4>
                    <h4  onClick={(e)=>{handleChangeForm({e,className:'right'})}} className="right">Use Coupon</h4> 
                </div>
                <div className="form">
                    { modalLeft() }
                    {modalRight()}
                </div>
            </div>
        </section>
    )
};


const mapStateToProps = ({data:{errorMessage,user}, public:{cabletv_variations,server_message}})=>({
    errorMessage,user,cabletv_variations,server_message
});

export default connect(mapStateToProps, null)(Cabletv);
