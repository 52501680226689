import React, { useState, useEffect } from 'react'
import './Electricity.css';
import './mobile.css';
import store from '../../../../store';
import { connect } from 'react-redux'
import { buyElecricity} from '../../../../actions/Action'
import { VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS } from '../../../../actions/public/types';
import { HandleVerifyCoupon } from '../../../../actions/public';
const Electricity = ({ errorMessage, server_message, electricity_variations, user }) => {

    const [pin, setPin] = useState('');
    const [amount, setAmount] = useState('');
    const [meterNumber, setMeterNumber] = useState('');
    const [selectedDisco, setSelectedDisco] = useState('');
    const [msg, setMsg] = useState('')
    const [disco, setDisco] = useState([]);
    const [serviceId, setServiceId] = useState('')
    const [amountToPay, setAmountToPay] = useState(0)
    const [variationCode, setVariationCode] = useState('');
    const [convFee, setConvFee] = useState();
    const [code, setCode] = useState('')
    const [showCodeField, setShowCodeField] = useState(true)
    //Check Error Message
    useEffect(() => {
        if (errorMessage && errorMessage.type === 'purchase_electric_fail') {
            setMsg(errorMessage.msg)
            // Utils.closePopUp();
        } else if (errorMessage && errorMessage.type === 'purchase_electric_success') {
            setMsg(errorMessage.msg);
            setPin('');
            setMeterNumber('')
            setAmount('')
            setAmountToPay(0)
            // Utils.closePopUp();
        } 
        
        if (server_message.type === VERIFY_COUPON_FAIL) {
            setMsg(server_message.info)
        } else if (server_message && server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'ELECTRICITY') {
            setShowCodeField(false)
            setAmount(parseInt(server_message.info.meta_data.amount))

        } else if (server_message && server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'ELECTRICITY') {
            setMsg('Invalid coupon code supplied.')
        }
    }, [errorMessage,server_message]);

    useEffect(() => {

        setDisco(electricity_variations)
        // return () => {
        //     cleanup
        // }
        setSelectedDisco(electricity_variations[0].name.toUpperCase())
        setConvFee(electricity_variations[0].wallet_convinience_fee)

    }, [electricity_variations]);

    useEffect(() => {
        if (amount && amount > 0) {

            const percentage = Math.round(((parseFloat(convFee) / 100) * parseInt(amount)));
            if (amount >= 1000) {
                const am = 100 + Math.round(parseInt(amount));/* capped at #100 for transaction > or == #1000 */
                setAmountToPay(am)
            } else {
                const am = (percentage + Math.round(parseInt(amount)));
                setAmountToPay(am)
            }
        }
    }, [amount]);

    useEffect(() => {
        // console.log(selectedDisco)
        if (disco && disco.length !== 0 && selectedDisco !== '') {
            // console.log(selectedDisco)
            let code = disco.find(d => {
                let name = d.name;

                return name.toUpperCase() === selectedDisco
            });

            setVariationCode(code.variation_code)
            setServiceId(code.service_id)
        }
    }, [selectedDisco])

    const orderE = () => {
        amount.toString()
        const space = meterNumber.match(/\s/g);
        const non_digit = meterNumber.match(/\D/g);
        const aplha = meterNumber.match(/[a-zA-Z]/g);
        const amountAlpha = amount.toString().match(/[a-zA-Z]/g);
        const amountSpace = amount.toString().match(/\s/g);
        const Amountnon_digit = amount.toString().match(/\D/g);

        if (parseFloat(amountToPay) > parseFloat(user.wallet_balance)) {
            return setMsg('Insufficient funds.');

        } else if (parseInt(amount) < 500) {
            return setMsg('Minimum amount of ₦500 can be recharged.');
        }
        else if (!amount) {
            return setMsg('Amount cannot be empty.');

        } else if (!meterNumber) {
            return setMsg('Meter number cannot be empty.');

        }
        else if (!space && !non_digit && !aplha
            && !amountAlpha && !amountSpace && !Amountnon_digit) {
            if (pin !== '') {
                setMsg('')

                const data = {
                    service_id:serviceId,
                    pin,
                    variation_code:variationCode,
                    amount,
                    meter_number:meterNumber,
                    coupon_code: code
                }

                store.dispatch(buyElecricity(data))
            } else {
                setMsg('Invalid Pin')
            }

        } else {
            setMsg('Invalid Meter Number or amount')
        }
    }

    const modalLeft = () => {
        return (
            <div className="modal-left">
                <form>
                    <h5>{msg}</h5>
                    <div className="select-disco">
                        <label>Discos</label>
                        <select onChange={e => setSelectedDisco(e.target.value)}>
                            {(disco.length > 0) ?
                                disco.map(d => (
                                    <option key={d.id}>{d.name.toUpperCase()}</option>
                                ))
                                : <option>select TV</option>}
                        </select>
                    </div>
                    <div className="amount">
                        <label>Amount</label>
                        <input onChange={(e) => setAmount(e.target.value)} value={amount} />
                    </div>
                    <div className="amount">
                        <label>Amount + Convenience Fee</label>
                        <input disabled={true} value={amountToPay} />
                    </div>
                    <div className="meter-number">
                        <label>Meter Number</label>
                        <input value={meterNumber} onChange={(e) => setMeterNumber(e.target.value)} />
                    </div>
                    <div className="pin">
                        <label>Transaction Pin</label>
                        <input value={pin} onChange={(e) => setPin(e.target.value)} />
                    </div>
                </form>
                <p onClick={() => orderE()}  >Order Now</p>
            </div>
        )
    }
    const loadCoupon = () => {
        if (!code) {
            return setMsg('Coupon code cannot be blank.')
        }
        const data = {
            coupon_code: code
        }
        setMsg('')
        
        store.dispatch(HandleVerifyCoupon(data))

    }


    const modalRight = () => {
        return (
            <div className="modal-right">
                {!showCodeField ? <>
                    <form>
                        <h5>{msg}</h5>
                        <div className="select-disco">
                            <label>Discos</label>
                            <select onChange={e => setSelectedDisco(e.target.value)}>
                                {(disco.length > 0) ?
                                    disco.map(d => (
                                        <option key={d.id}>{d.name.toUpperCase()}</option>
                                    ))
                                    : <option>select TV</option>}
                            </select>
                        </div>
                        <div className="amount">
                            <label>Amount</label>
                            <input disabled={true} value={amount} />
                        </div>
                        <div className="meter-number">
                            <label>Meter Number</label>
                            <input value={meterNumber} onChange={(e) => setMeterNumber(e.target.value)} />
                        </div>
                        <div className="pin">
                            <label>Transaction Pin</label>
                            <input value={pin} onChange={(e) => setPin(e.target.value)} />
                        </div>
                    </form>
                    <p onClick={() => orderE()}  >Order Now</p>
                </>
                    :
                    <>
                        <form>
                            <h5>{msg}</h5>
                            <div>
                                <div className="coupon-code">
                                    <label>Enter coupon code</label>
                                    <input onChange={(e) => setCode(e.target.value)} value={code} />
                                </div>
                            </div>
                        </form>
                        <p onClick={() => loadCoupon()} >Load</p>
                    </>
                }
            </div>
        )
    }

    const handleChangeForm = ({ e, className }) => {
        const modalRight = document.getElementsByClassName('modal-right')
        const modalLeft = document.getElementsByClassName('modal-left')
        setShowCodeField(true)
        setMsg('')
        if (className === 'right') {
            //Remove Active class from left
            e.currentTarget.previousElementSibling.classList.remove('left-active')
            e.currentTarget.classList.add('right-active');
            modalLeft[0].style.display = 'none';
            modalRight[0].style.display = 'flex'
        }


        if (className === 'left') {
            //Remove Active class from right
            e.currentTarget.nextElementSibling.classList.remove('right-active')
            e.currentTarget.classList.add('left-active');
            modalLeft[0].style.display = 'flex';
            modalRight[0].style.display = 'none'
        }
    }

    return (
        <section className="electricity">
            <h3>Electricity</h3>
            <div className="wrap">
                <div className="header">
                    <h4 onClick={(e) => { handleChangeForm({ e, className: 'left' }) }} className="left left-active">Use wallet</h4>
                    <h4 onClick={(e) => { handleChangeForm({ e, className: 'right' }) }} className="right">Use Coupon</h4>
                </div>
                <div className="form">
                    {modalLeft()}
                    {modalRight()}
                </div>

            </div>
        </section>
    )
};

const mapStateToProps = ({ data: { errorMessage, user }, public: { server_message, electricity_variations } }) => ({

    errorMessage, server_message, electricity_variations, user

})

export default connect(mapStateToProps, null)(Electricity);
