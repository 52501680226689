import React,{useState,useEffect} from 'react'
import './Users.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions';
const Users = ({setPageToShow,setUserDetails,Users}) => {
    const [ filtered, setFiltered ] = useState( [] );
    const [searchBar,setSearchBar] =useState('')
    
    const setStatusBackground = ( user ) => {
        if ( user.status === 'online' ) {
            return { background: "#5df888" };
        } else if ( user.status === 'offline' ) {
            return { background: "#f85d5d" };
        }
    };

    useEffect( () => {
        setFiltered( Users );
    },[Users])

    useEffect( () => {
        

        if ( searchBar ) {
            let fill = Users.filter( user => user.email === searchBar );
        setFiltered(fill )
        } else {
            setFiltered( Users );
        }
        
    },[searchBar])


      
    const formatDate = (date) => {
        let month = new Date( parseInt(date) ).getMonth();
        let day = new Date( parseInt(date) ).getDate();
        const year = new Date( parseInt( date ) ).getFullYear();
        
        if ( day < 10 ) {
            day = '0' + day
        }

        if ( month < 10 ) {
            month = '0' + month
        }

        return `${day} - ${month} - ${year}`
    }


    const tableData = (user) => {
        return (
             <div key={user.id} className="table-data" onClick={()=>Open(user)}>
                     <ul>
                        <li>{(user.isAdmin)?'admin': 'user'}</li>
                        <li>{user.name}</li>
                        <li className="hide-on-mobile"> {user.email}</li>
                        <li>{Utils.formatCurrency(parseInt(user.wallet_balance))}</li>
                        <li style={setStatusBackground(user)} className="status">{user.status}</li>
                    </ul>
            </div>
        )
    }


    const Open = (data) => {
        setPageToShow( 'user' );
        setUserDetails(data)
        Utils.showAdminPopUp();
    }
    return (
        <section className="admin-users">
            <div className="header">
                <h3>Users</h3>
                <input onChange={(e)=>setSearchBar(e.target.value)} type="text" placeholder="Search"/>
            </div>
                <div className="table">
                    <div className="table-label">
                        <ul>
                            <li>Role</li>
                            <li>Name</li>
                            <li className="hide-on-mobile">Email</li>
                            <li>Wallet Balance</li>
                            
                            <li>Status</li>
                        </ul>
                    </div>
                    { ( filtered ) ?
                        filtered.map( user => (
                            tableData(user)
                        ))
                    : null }
            </div>
        </section>
    )
}

export default Users
