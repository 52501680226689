import React, {useState,useEffect} from 'react'
import './Settings.css';
import './mobile.css';
import Utils from '../../../Utils/Functions'
import store from '../../../../store';
import {changePassword,resetSecretPin} from '../../../../actions/Action';
import { connect } from 'react-redux';
import { CHANGE_PASS_FAIL, CHANGE_PASS_SUCCESS, RESET_PIN_FAIL, RESET_PIN_PASS } from '../../../../actions/types';
const Settings = ({User,errorMessage}) => {

    const [ oldPassword, setOldPassword ] = useState( '' );
    const [ newPassword, setNewPassword ] = useState( '' );
    const [ confirmNewPassword, setConfirmNewPassword ] = useState( '' );
    const [error, setError] = useState('')
    const [pinError, setPinError] = useState('')
    const [ password, setPassword ] = useState( '' );
    const [ pin, setPin ] = useState( '' );
    const [ showKey, setShowKey ] = useState( false );
    const [disable,setDisable]=useState(true)

    useEffect( () => {
        if ( errorMessage && errorMessage.type === CHANGE_PASS_FAIL ) {
            setError( errorMessage.msg );
            Utils.closePopUp()
        } else if( errorMessage && errorMessage.type === CHANGE_PASS_SUCCESS ) {
                setOldPassword('')
                setNewPassword('')
                setConfirmNewPassword('')
                setError( errorMessage.msg );
                Utils.closePopUp()
                
            }else if(errorMessage && errorMessage.type === RESET_PIN_FAIL ){
                setPinError( errorMessage.msg );
                Utils.closePopUp()
        } else if ( errorMessage && errorMessage.type === RESET_PIN_PASS) {
                setShowKey(true)
                setPassword('')
                setPinError( errorMessage.msg );
                setPin('')
                Utils.closePopUp()
            }
        

            return ()=> console.log('')
        
    },[errorMessage])

    
   
    //Reset Secret Pin

    const resetPin = () => {
        if ( !password ) {
            return setPinError( 'Please enter your current password.' );
        };


        if ( !pin ) {
            return setPinError( 'Opps a problem occurred.' );
        };

        const pin_contains_non_digit= pin.match(/\D/g);
        const pin_contains_letter = pin.match(/[a-zA-Z]/g);
        const pin_contains_space =pin.match(/\s/g);
        const pin_length =pin.length;

        if(pin_contains_letter || pin_contains_non_digit || pin_contains_space){
            return setPinError( 'You have entered an invalid pin.' );
        };

        if(pin_length !== 6){
            return setPinError( 'Pin must be six(6) numbers.' );
        };
        let data = {
            password,
            pin
        }

        store.dispatch(resetSecretPin(data))
    };
    
    //Reset Password
    const changePasswordNow = () => {

        const length = newPassword.match( /[A-Z0-9a-z]{8,}/g );

        if ( !oldPassword ) {
            return setError( 'Please enter current password.' );
        }

        if ( !newPassword ) {
            return setError( 'Please enter your new password.' );
        }

        if ( !length ) {
            return setError( 'Password too short.' );
        }


        if ( newPassword !== confirmNewPassword ) {
            return setError( 'Passwords not equal' );
        }

        const data = {
            old_password:oldPassword,
            new_password:newPassword
        }
        store.dispatch(changePassword(data))
    }

    const showDetails = ({e,className}) => {
        const form = document.getElementsByClassName( className );
        
        
       
        form[ 0 ].classList.add( 'show-form' );

        //Hide Chev Down
        const hideChevDown = e.currentTarget
        hideChevDown.style.display = "none";

        //Show Chev Up
        const showChevUp = e.currentTarget.nextElementSibling;
        showChevUp.style.display="block"
    }

    const hideDetails = ( {e,className}) => {
        const form = document.getElementsByClassName( className );

        form[ 0 ].classList.remove( 'show-form' );


        //Show Chev Down
        const showChevDown = e.currentTarget.previousElementSibling
        showChevDown.style.display = "block";

        //Hide Chev Up
        const hideChevUp = e.currentTarget;
        hideChevUp.style.display="none"
    }

    const copyToClipBoard = (id) => {
    setDisable(false)
        setTimeout( () => {
            if(id){
                var data = document.getElementById(id);
        
            /* Select the text field */
            data.select();
            // password.setSelectionRange(0, 99999);
          
            /* Copy the text inside the text field */
                document.execCommand( "copy" );
               
                let c = document.getElementsByClassName( 'copy-success-message' );
                c[ 0 ].style.display = "block"
                // console.log(c)
                setTimeout( () => {
                    c[ 0 ].style.display = "none"
                },1000)

            }
        },100)
        
      
       
        
           
    };



    return (
        <section className="settings">
            <h3>Settings</h3>
            <div>
                <div className="profile">
                    <div className="header">
                        <h2>Profile</h2>
                        <i className="fa fa-chevron-down" onClick={(e)=>showDetails({e, className:"profile-form"})}></i>
                        <i className="fa fa-chevron-up" onClick={(e)=>hideDetails({e, className:"profile-form"})}></i>
                    </div>
                    <div className="form profile-form">
                        <form>
                        <div>
                            <label>Name</label>
                                <input disabled={ true } tyep="text" value={User? User.name.toUpperCase():null}/>
                        </div>
                        <div>
                            <label>Email</label>
                            <input disabled={true} type="email" value={User? User.email:null}/>
                        </div>
                        <div>
                            <label>Phone</label>
                            <input disabled={true} value={User? User.phone:null}/>
                        </div>
                        <div>
                            <label>Referral Link</label>
                                <input disabled={ disable } id="referral-link-input" value={ User && User.referral_code ? `https://creditsync.com.ng/auth/signup?referral_code=${ User.referral_code }` : 'N/A' } />
                                <i className="fa fa-copy" onClick={()=>User && User.referral_code?copyToClipBoard("referral-link-input"):null}></i>
                                <p className="copy-success-message">Copied</p>
                        </div>
                        </form>
                    </div>
                </div>
                <div className="password">
                    <div className="header">
                        <h2>Change Password</h2>
                        <i className="fa fa-chevron-down" onClick={(e)=>showDetails({e, className:"change-password-form"})}></i>
                        <i className="fa fa-chevron-up" onClick={(e)=>hideDetails({e, className:"change-password-form"})}></i>
                    </div>
                    <div className="form change-password-form">
                        <h4>{ error}</h4>
                        <form>
                        <div>
                            <label>Current Password</label>
                            <input type="password" onChange={(e)=>setOldPassword(e.target.value)} value={oldPassword} />
                        </div>
                        <div>
                            <label>New Password</label>
                            <input type="password" onChange={(e)=>setNewPassword(e.target.value)} value={newPassword} />
                            <h6><i className="fa fa-info-circle"></i> password must be atleast 8 characters.</h6>
                        </div>
                        <div>
                            <label>Confirm New Password</label>
                                <input type="password" onChange={ ( e ) => setConfirmNewPassword( e.target.value ) } value={ confirmNewPassword}/>
                        </div>
                        </form>

                        <p onClick={()=>changePasswordNow()}>Change</p>
                    </div>
                </div>
                <div className="pin">
                    <div className="header">
                        <h2>Reset Transaction Pin</h2>
                        <i className="fa fa-chevron-down" onClick={(e)=>showDetails({e, className:"change-pin-form"})}></i>
                        <i className="fa fa-chevron-up" onClick={(e)=>hideDetails({e, className:"change-pin-form"})}></i>
                    </div>
                    <div className="form change-pin-form">
                        <h4>{ pinError}</h4>
                        <form>
                        <div>
                            <label>Current Password</label>
                            <input type="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter your current password." value={password} />
                        </div>
                        <div>
                            <label>Pin</label>
                            <input value={pin} onChange={e=>setPin(e.target.value)} type="text"   />
                            <h6><i className="fa fa-info-circle"></i>Kindly keep note of the secret key.</h6>
                        </div> 
                         <p onClick={()=>resetPin()}>Reset</p>
                        </form>

                        
                    </div>
                </div>
                
                <div className="billing-info">
                </div>
            </div>
        </section>
    )
};

const mapStateToProps =({data:{user,errorMessage}})=>({
    User:user,
    errorMessage
})

export default connect(mapStateToProps,null)(Settings)
