import React from 'react'
import './Popups.css'
import User from './Users/User';
import Loading from './Loading/Loading';
import TDetails from './TDetails/TDetails';
import Pdetails from './PDetails/PDetails';
import Data from './Datadetails/Datadetails';
import CableTV from './CableDetails/Cable'

const Popups = ({pageToShow,dataDetails,errorMessage, cable, userDetails,Tdetails,paymentDetails}) => {
    return (
        <section className="popups-admin" >
            {(pageToShow === 'user')? 
            <User errorMessage={errorMessage} userDetails={userDetails}/>
                : ( pageToShow === 'transactiondetails' ) ? 
                <TDetails Tdetails={ Tdetails}/>
                : ( pageToShow === 'paymentdetails' ) ? 
                <Pdetails paymentDetails={ paymentDetails } />
                :(pageToShow === 'datadetails')?
                <Data dataDetails={ dataDetails }/>
                :(pageToShow  === 'cabletvdetails')?<CableTV cable={cable}/>
                :<Loading />}
        </section>
    )
}

export default Popups
