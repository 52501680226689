import React,{useState,useEffect} from 'react'
import './Data.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions';
import { connect } from 'react-redux';
const Data = ({setDataDetails,data_variations, setPageToShow}) => {

    const [ plans, setPlans ] = useState();
    const [ planId, setplanId ] = useState('');
    const [ amount, setAmount ] = useState();
    const [ planName, setPlanName ] = useState( '' );
    const [dataSubriptions,setDataSubcriptions]=useState([])
   const [ selectedNetwork, setSelectedNetwork ] = useState(  );


    
    useEffect( () => {
        setDataSubcriptions( data_variations ) 
    }, [ data_variations ] );

    useEffect( () => {
        if(dataSubriptions.length !== 0)
        setSelectedNetwork(dataSubriptions[0].name)
    },[dataSubriptions])

       //Update Data Plans to Show
    useEffect( () => {
        if ( selectedNetwork ) {
            const dataPlans = dataSubriptions.filter( d => d.name === selectedNetwork );
            if ( dataPlans.length !== 0 ) {
                
                setPlans( dataPlans[ 0 ].plans )
                
            } else {
                setPlans()
            }
            
        }
    }, [ selectedNetwork ] );

    
    const editData = (data) => {
        setPageToShow( 'datadetails' );

        let d = {
            data,
            selectedNetwork
        }
        setDataDetails( d );
        Utils.showAdminPopUp()
    }

    const data = ( data ) => {
                
        return (
            <div className="floating-inputs" key={data.id} onClick={()=>editData(data)}>
                <div className="inputs"  >
                    <input disabled  id={data.id} className="name" placeholder="Name" value={data.name} />
                    <input disabled  id={data.id}   className="price" placeholder="Price"  value={data.wallet_price}  />
                </div>
                
            </div>
        )
    };

    const newData =()=>{
        return (
            <div className="floating-inputs new" >
                <div className="inputs"  >
                    <input onChange={(e)=> setPlanName(e.target.value)} className="name" placeholder="Name" />
                    <input onChange={(e)=> setAmount(e.target.value)}  className="price" placeholder="Price"   />
                </div>
                <div className="options">
                    <p>Add</p>
                </div>
            </div>
       )
    }


    // useEffect( () => {
    //     if ( plans ) {
    //         setAmount( Utils.formatCurrency(parseInt(plans[ 0 ].price)) );
    //         setplanId(plans[0].id)
    //     } else {
    //         setAmount( '' );
    //         setplanId('')
    //     }
    // },[plans]);

    return (
        <section className="add-edit-data">
            <h3>Update Data Subcriptions</h3>
            <div>
                <form>
                    <div>
                        <label>Select Network</label>
                        <select  onChange={(e)=>setSelectedNetwork(e.target.value)}>
                        { ( dataSubriptions.length > 0) ? dataSubriptions.map( ( network ) => (
                            <option key={network.id}>{ network.name}</option>
                            )) :<option>Select Network</option>}
                            
                        </select>
                    </div>
                    { ( plans ) ? plans.map( p => (
                        data(p)
                    ) ) : null }
                   
                </form>
            </div>
        </section>
    )
};


const mapStateToProps =({public:{data_variations}})=>({
data_variations
})

export default connect(mapStateToProps, null)(Data)
