import React, { useState, useEffect } from 'react'
import './style.css';
import './mobile.css';
import store from '../../../store';
import { useHistory } from 'react-router';
import { CREATE_SECRET_PIN} from '../../../actions/Action';
import { connect } from 'react-redux';
import Utils from '../../Utils/Functions'
import { CREATE_PIN_FAIL,CREATE_PIN_SUCCESS } from '../../../actions/types';
const Index = ({ data }, props) => {
    const [msg, setMsg] = useState('');
    const [pin, setPin] = useState('');

    const { errorMessage } = data;
    const history = useHistory()
    useEffect(() => {
        if (errorMessage && errorMessage.type === CREATE_PIN_FAIL) {
            setMsg(errorMessage.msg);
             Utils.closePopUp()
        };

        if (errorMessage && errorMessage.type === CREATE_PIN_SUCCESS) {
           
            history.push('/user/dashboard');
            // Utils.closePopUp()
        };

       
        

        return () => console.log('');
    }, [errorMessage]);





    

    const submit = () => {
        
        if(!pin){
            return setMsg( 'pin is required.' );
        };

        const pin_contains_non_digit= pin.match(/\D/g);
        const pin_contains_letter = pin.match(/[a-zA-Z]/g);
        const pin_contains_space =pin.match(/\s/g);
        const pin_length =pin.length;

        if(pin_contains_letter || pin_contains_non_digit || pin_contains_space){
            return setMsg( 'You have entered an invalid pin.' );
        };

        if(pin_length !== 6){
            return setMsg( 'Pin must be six(6) numbers.' );
        };

        setMsg('');
        const payload={pin}
        store.dispatch(CREATE_SECRET_PIN(payload))

    }


    return (
        <section className="login-page">


            <div>
                <h1>Create your six(6) digit transaction pin</h1>
                <h3>{msg}</h3>
                <form>
                    <div className="key">
                        <i className="fa fa-key"></i>
                        <input  type="text" value={pin} onChange={(e)=> setPin(e.target.value)}  placeholder="secret pin" />
                    </div>
                    
                    
                </form>
                <p onClick={() => submit()} >PROCEED</p>

                <h4 className="pin-info"><i className="fa fa-info-circle"></i> Kindly memorize the pin as it will be needed later.</h4>
            </div>

        </section>
    );
};

const mapStateToProps = ({ data }) => ({
    data
});

export default connect(mapStateToProps, null)(Index);
