import Axios from 'axios';
import { GET_CABLETV, GET_DATA_VARIATIONS, GET_EDUCATION_VARIATIONS, GET_ELECTRICITY_VARIATIONS, GET_NETWORKS, VERIFY_PHONENUMBER_FAIL, VERIFY_PHONENUMBER_SUCCESS, VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS, VERIFY_METER_FAIL, VERIFY_METER_SUCCESS, VERIFY_SMARTCARD_FAIL, VERIFY_SMARTCARD_SUCCESS, PURCHASE_AIRTIME_FAIL, PURCHASE_AIRTIME_SUCCESS, PURCHASE_CABLE_FAIL, PURCHASE_CABLE_SUCCESS, PURCHASE_DATA_FAIL, PURCHASE_DATA_SUCCESS, PURCHASE_EDUCATION_FAIL, PURCHASE_EDUCATION_SUCCESS, PURCHASE_ELECTRICITY_FAIL, PURCHASE_ELECTRICITY_SUCCESS } from './types';
import store from '../../store';
import settings from '../settings'
import { userLoad } from '../Action'
const config = {
    headers: {
        "Content-Type": "application/json"
    }
};


const baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:8000/' : 'https://api.creditsync.com.ng/';

export const HandleLoadingDataVaritations = () => async (dispatch) => {
    try {

        let response = await Axios.get(baseURL + 'api/public/product/data/all', config);
        response = response.data;
        return dispatch({
            type: GET_DATA_VARIATIONS,
            payload: response.message
        });

    } catch (error) {

        // if ( error.response.data ) {
        //     error = error.response.data;
        // }
        // console.log( error.message )
    }
};


export const HandleLoadingNetworks = () => async (dispatch) => {
    try {

        let response = await Axios.get(baseURL + 'api/public/product/network/all', config);
        response = response.data;
        return dispatch({
            type: GET_NETWORKS,
            payload: response.message
        });

    } catch (error) {
        // error = error.response.data;
        // console.log( error )
    }
};


export const HandleLoadingCableTV = () => async (dispatch) => {
    try {

        let response = await Axios.get(baseURL + 'api/public/product/cabletv/all', config);
        response = response.data;
        return dispatch({
            type: GET_CABLETV,
            payload: response.message
        });

    } catch (error) {
        // error = error.response.data;
        // console.log( error )
    }
};


export const HandleLoadingElectricityVariations = () => async (dispatch) => {
    try {

        let response = await Axios.get(baseURL + 'api/public/product/electricity/all', config);
        response = response.data;
        return dispatch({
            type: GET_ELECTRICITY_VARIATIONS,
            payload: response.message
        });

    } catch (error) {
        // error = error.response.data;
        // console.log( error )
    }
};

export const HandleLoadingEducationVariations = () => async (dispatch) => {
    try {

        let response = await Axios.get(baseURL + 'api/public/product/education/all', config);
        response = response.data;
        return dispatch({
            type: GET_EDUCATION_VARIATIONS,
            payload: response.message
        });

    } catch (error) {
        // error = error.response.data;
        // console.log( error )
    }
};


export const HandleVerifyPhoneNumber = (phone_number) => async (dispatch) => {
    store.dispatch(userLoad(true))
    try {

        const body = JSON.stringify(phone_number);

        let response = await Axios.post(baseURL + 'api/public/verify/phonenumber', body, config);
        response = response.data;
        dispatch({
            type: VERIFY_PHONENUMBER_SUCCESS,
            payload: {
                type: VERIFY_PHONENUMBER_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false))

    } catch (error) {

        dispatch({
            type: VERIFY_PHONENUMBER_FAIL,
            payload: {
                type: VERIFY_PHONENUMBER_FAIL,
                info: error.response ? error.response.data.message : 'Opps, error processing your request.'
            }
        });
        store.dispatch(userLoad(false))
    };



};

export const HandleVerifyCoupon = (coupon_code) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true))
        const body = JSON.stringify(coupon_code);

        let response = await Axios.post(baseURL + 'api/public/verify/coupon', body, config);

        response = response.data;

        dispatch({
            type: VERIFY_COUPON_SUCCESS,
            payload: {
                type: VERIFY_COUPON_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false))

    } catch (error) {

        dispatch({
            type: VERIFY_COUPON_FAIL,
            payload: {
                type: VERIFY_COUPON_FAIL,
                info: error.response ? error.response.data.message : 'Opps, error processing your request.'
            }
        });

        store.dispatch(userLoad(false))
    }
};


export const HandleVerifySmartCardNumber = (data) => async (dispatch) => {
    try {

        store.dispatch(userLoad(true))

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/verify/smartcard', body, config);
        response = response.data;
        dispatch({
            type: VERIFY_SMARTCARD_SUCCESS,
            payload: {
                type: VERIFY_SMARTCARD_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false))

    } catch (error) {

        dispatch({
            type: VERIFY_SMARTCARD_FAIL,
            payload: {
                type: VERIFY_SMARTCARD_FAIL,
                info: error.response ? error.response.data.message : 'Opps, error processing your request.'
            }
        });

        store.dispatch(userLoad(false))
    }
};


export const HandleVerifyMeterNumber = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true))
        const body = JSON.stringify(data);
        let response = await Axios.post(baseURL + 'api/public/verify/meternumber', body, config);
        response = response.data;
        dispatch({
            type: VERIFY_METER_SUCCESS,
            payload: {
                type: VERIFY_METER_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false))

    } catch (error) {

        dispatch({
            type: VERIFY_METER_FAIL,
            payload: {
                type: VERIFY_METER_FAIL,
                info: error.response ? error.response.data.message : 'Opps, error processing your request.'
            }
        });

        store.dispatch(userLoad(false))
    }
};


export const HandlePurchaseAirtime = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true));

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/airtime/buy', body, config);
        response = response.data;
        dispatch({
            type: PURCHASE_AIRTIME_SUCCESS,
            payload: {
                type: PURCHASE_AIRTIME_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false));

    } catch (error) {
        error = error.response.data;
        dispatch({
            type: PURCHASE_AIRTIME_FAIL,
            payload: {
                type: PURCHASE_AIRTIME_FAIL,
                info: error.message
            }
        });

        store.dispatch(userLoad(false));
    }
};

export const HandlePurchaseData = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true));

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/data/buy', body, config);
        response = response.data;
        dispatch({
            type: PURCHASE_DATA_SUCCESS,
            payload: {
                type: PURCHASE_DATA_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false));

    } catch (error) {
        error = error.response.data;
        dispatch({
            type: PURCHASE_DATA_FAIL,
            payload: {
                type: PURCHASE_DATA_FAIL,
                info: error.message
            }
        });

        store.dispatch(userLoad(false));
    }
};


export const HandlePurchaseCable = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true));

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/cable/buy', body, config);
        response = response.data;
        dispatch({
            type: PURCHASE_CABLE_SUCCESS,
            payload: {
                type: PURCHASE_CABLE_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false));

    } catch (error) {
        error = error.response.data;
        dispatch({
            type: PURCHASE_CABLE_FAIL,
            payload: {
                type: PURCHASE_CABLE_FAIL,
                info: error.message
            }
        });

        store.dispatch(userLoad(false));
    }
};

export const HandlePurchaseEducation = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true));

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/education/buy', body, config);
        response = response.data;
        dispatch({
            type: PURCHASE_EDUCATION_SUCCESS,
            payload: {
                type: PURCHASE_EDUCATION_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false));

    } catch (error) {
        error = error.response.data;
        dispatch({
            type: PURCHASE_EDUCATION_FAIL,
            payload: {
                type: PURCHASE_EDUCATION_FAIL,
                info: error.message
            }
        });

        store.dispatch(userLoad(false));
    }
};
export const HandlePurchaseElectricity = (data) => async (dispatch) => {
    try {
        store.dispatch(userLoad(true));

        const body = JSON.stringify(data);

        let response = await Axios.post(baseURL + 'api/public/electricity/buy', body, config);
        response = response.data;
        dispatch({
            type: PURCHASE_ELECTRICITY_SUCCESS,
            payload: {
                type: PURCHASE_ELECTRICITY_SUCCESS,
                info: response.message
            }
        });

        store.dispatch(userLoad(false));

    } catch (error) {
        error = error.response.data;
        dispatch({
            type: PURCHASE_ELECTRICITY_FAIL,
            payload: {
                type: PURCHASE_ELECTRICITY_FAIL,
                info: error.message
            }
        });

        store.dispatch(userLoad(false));
    }
};