import React,{useState,useEffect} from 'react'
import './SharedWallet.css';
import './mobile.css';
import store from '../../../../../store'
import { shareWallet,userLoad } from '../../../../../actions/Action';
import { connect } from 'react-redux';
import Utils from '../../../../Utils/Functions'
import { SHARE_FAIL, SHARE_SUCCESS } from '../../../../../actions/types';
const SharedWallet = ({User,errorMessage}) => {

    const [ email, setEmail ] = useState( '' );
    const [ pin, setPin ] = useState( '' );
    const [ amount, setAmount ] = useState( '' );
    const [ error, setError ] = useState( '' );



    useEffect( () => {
        if ( errorMessage && errorMessage.type === SHARE_FAIL ) {
            setError( errorMessage.msg );
            Utils.closePopUp()
        } else if ( errorMessage && errorMessage.type === SHARE_SUCCESS ) {
            setError( errorMessage.msg );
            setAmount('')
            setPin('')
            setEmail( '' );
            Utils.closePopUp();
        };

        return ()=>console.log('')
    },[errorMessage])


    const share = () => {
        const amountAlpha = amount.match( /[a-zA-Z]/g );
        const amountSpace = amount.match( /\s/g );
        const Amountnon_digit = amount.match( /\D/g );
        const checkName = email.match( /^[^\s@]+@[^\s@]+\.[^\s@]+$/ );
        if ( !amount || amountAlpha || amountSpace || Amountnon_digit ) {
            return setError( 'Invalid Amount.' );
        };

        if ( !checkName ) {
            return setError('Please enter a valid email.')
        };

        if ( !pin ) {
            return setError('Invalid Secret Pin.')
        };

        if ( parseInt(amount) <= 0 ) {
            return setError('Insufficient funds.')
        }

        if ( parseInt(amount) > parseInt(User.wallet_balance) ) {
            return setError('Insufficient funds.')
        }
        setError( '' );

        const data = {
            amount,
            email,
            pin
        }
        store.dispatch(shareWallet(data));
    }
    return (
        <div className="shared-wallet">
            <div  className="left">
                <h3>Share Funds</h3>
                <h5>{ error}</h5>
                <form>
                    <div className="email">
                        <label>Email</label>
                        <input type="text" value={email} placeholder="Enter recipient's email" onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div className="amount">
                        <label>Amount</label>
                        <input value={amount}  onChange={(e)=>setAmount(e.target.value)} />
                    </div>
                    <div className="pin">
                        <label>Secret Pin</label>
                        <input value={ pin} type="password" placeholder="Enter your secret pin" onChange={(e)=>setPin(e.target.value)}/>
                        
                    </div>
                </form>
            </div>
            <div className="right">
                <p onClick={ ()=>share()}>Share</p>
            </div>
        </div>
    )
}

const mapStateToProps = ( { data: { errorMessage } } ) => ( {
    errorMessage
})

export default connect(mapStateToProps, null)(SharedWallet)
