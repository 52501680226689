import React, {useEffect} from 'react';
import Routing from './Routing'
import { connect } from 'react-redux';
import Utils from './components/Utils/Functions'
import PopUps from './components/PopUps/PopUps';
import store from './store';

import { authenticateUserFromToken, loadAllProduct, refreshToken } from './actions/Action';
import {HandleLoadingDataVaritations,HandleLoadingCableTV,HandleLoadingEducationVariations,HandleLoadingElectricityVariations,HandleLoadingNetworks} from './actions/public';


const App =({user,errorMessage,isAuthenticated,user_loading,isAdmin,transactions,token,products})=> {
       
        let data = {
                
                isAuthenticated,
                user,
                token,
                isAdmin,
                products
        }
        
        useEffect( () => {
                
                // store.dispatch( getUser() );
                if ( user_loading ) {
                        Utils.showPopUp()
                } else if (!user_loading){
                        Utils.closePopUp()
                }
                // console.log(user)
                // setAuthenticated(isAuthenticated)
              return ()=>{
                      console.log('')
              }
        }, [ user_loading ] );

        useEffect( () => {
                if ( !token ) {
                        store.dispatch( authenticateUserFromToken() )
                }

                store.dispatch( HandleLoadingDataVaritations() );
                store.dispatch( HandleLoadingCableTV() );
                store.dispatch( HandleLoadingElectricityVariations() );
                store.dispatch( HandleLoadingEducationVariations() );
                store.dispatch( HandleLoadingNetworks() );
                // store.dispatch( loadAllProduct() );
              
                
                return ()=>{
                        console.log('')
                }   
        },[token])
        useEffect( () => {
                if ( token ) {
                        store.dispatch(refreshToken())
                }

                return ()=>{
                        console.log('')
                }
        },[])
        return (
                
                
                
                
                                
                                
                <>
                        
                <Routing data={data} />
                        <PopUps errorMessage={ errorMessage}/>   
                </>
                               


                
                    
                
               
                
        )
    
}

const mapStateToProps = ( { data: {user,token,isAuthenticated,errorMessage,isAdmin,user_loading,transactions,products } } ) => ( {
        // data:state.data
        isAuthenticated,
        user_loading,
        user,
        transactions,
        token,
        isAdmin,
        errorMessage,
        products
        
        
} );

export default connect(mapStateToProps,null)(App)
