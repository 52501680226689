import React from 'react'
import './PersonalWallet.css';
import './mobile.css';
import Utils from '../../../../Utils/Functions'
const PersonalWallet = ({User, setPageToShow}) => {

    const show = () => {
        setPageToShow( 'Payment' );
        Utils.showPopUp()
    }


    return (
        <div className="personal-wallet">
            <div className="left">
                <h3>Your Wallet</h3>
                <h5><p>NGN</p>{User? Utils.currency( parseFloat(User.wallet_balance)) :null}</h5>
            </div>
            <div className="right">
                <p onClick={()=>show()}>Fund Wallet</p>
            </div>
        </div>
    )
}

export default PersonalWallet
