import { combineReducers } from 'redux';
import Reducer from './Reducer'
import PublicReducer from './public'



export default combineReducers({
  data: Reducer,
  public:PublicReducer
 
  
  
  
});