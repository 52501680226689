import React from 'react'
import './PDetails.css';
import './mobile.css';
import Utils from '../../../Utils/Functions'

const PDetails = ( { paymentDetails } ) => {
    
    const formatDate = (date) => {
        let hours = new Date( parseInt(date) ).getHours() % 12;//To Get Local hours
        const minutes = new Date( parseInt(date) ).getMinutes();
        let q = 'PM'

        const months = [ 'January', 'February', 'March', 'April', 'May',
            'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        
        const days =['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
        let Nday = new Date( parseInt(date) ).getDate();
        let day = days[ new Date( parseInt( date ) ).getDay()];
        let month = months[new Date( parseInt( date ) ).getMonth()];
        let year = new Date( parseInt( date ) ).getFullYear();

        if ( new Date( ( parseInt( date ) ) ).getHours() < 12 ) {
             q = 'AM'
        }
        if ( Nday < 10 ) {
            Nday = '0' + Nday
        }

        let newDate = new Date( parseInt( date ) );
        
        if ( hours === 0 ) {
            hours = 12
        }
        const d = {
            time: `${ new Date(parseInt( date ) ).toLocaleString()}`,
            date: ` ${ day }  ${Nday} ${ month }, ${ year }`,
         
            newDate
        }

        return d
    };
    
    return (
        <div className="payment-details slowly-show-popups">
            <div className="right">
                <p>Payment ID: { paymentDetails.id}</p>
                <p>Payment REF: { paymentDetails.payment_reference}</p>
                
                <p>Payment Time: { formatDate( paymentDetails.date_of_payment ).time}</p>
                <p>Payment Date: { formatDate( paymentDetails.date_of_payment ).date}</p>
                <p>User: { paymentDetails.user}</p>
                <p>User ID: { paymentDetails.user_id}</p>
                
            </div>
            
            <div className="left">
                <p onClick={ () => Utils.closeAdminPopUp() }>Close</p>
            </div>
        </div>
    )
}

export default PDetails
