import React,{useEffect,useState} from 'react';
import './education.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
import store from '../../../../store';
import {connect} from 'react-redux'
import {buyEducationPayments} from '../../../../actions/Action'
import { VERIFY_COUPON_FAIL, VERIFY_COUPON_SUCCESS } from '../../../../actions/public/types';
import { HandleVerifyCoupon } from '../../../../actions/public';
const Education = ( {errorMessage,user,education_variations,server_message } ) => {
    const [ pin, setPin ] = useState( '' );
    const [ amount, setAmount ] = useState();
    const [ plans, setPlans ] = useState();
    const [ planId, setplanId ] = useState('');
    const [ plan, setPlan ] = useState( '' );
    const [ msg, setMsg ] = useState( '' );
    const [ serviceId, setServiceId ] = useState( '' );
    const [ selectedType, setSelectedType ] = useState( '' );
    const [ educationPlans, seteducationPlans ] = useState( [] );
    const [convFee, setConvFee] = useState();
    const [ amountToPay, setAmountToPay ] = useState( 0 );
    const [unit, setUnit] = useState(1);
    const [ code, setCode ] = useState( '' );

    
    //Check Error Message
    useEffect( () => {
        if ( errorMessage && errorMessage.type === 'purchase_education_fail' ) {
            setMsg( errorMessage.msg )
            // Utils.closePopUp();
        } else if ( errorMessage && errorMessage.type === 'purchase_education_success' ) {
            setMsg( errorMessage.msg );
            setPin( '' );
            
            // Utils.closePopUp();
        } 
        
        if ( server_message.type === VERIFY_COUPON_FAIL ) {
            setMsg( server_message.info )
        } else if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product === 'EDUCATION' ) {
            //
            setplanId( server_message.info.meta_data.plan_id )
            setPlan( server_message.info.meta_data.plan )
            // setNetworkId( server_message.info.meta_data.networkId )
            setAmount( Utils.formatCurrency( parseInt( server_message.info.meta_data.amount ) ) );
            setServiceId( server_message.info.meta_data.service_id ? server_message.info.meta_data.service_id : '' )
            setSelectedType( server_message.info.meta_data.name )
        } else if ( server_message.type === VERIFY_COUPON_SUCCESS && server_message.info.meta_data.product !== 'EDUCATION' ) {
            setMsg( 'Invalid coupon code supplied.' )
        }
        return ()=> console.log('')
    }, [ errorMessage, server_message ] );

    useEffect( () => {
        
        seteducationPlans( education_variations );

        return ()=> console.log('')
        
    }, [ education_variations ] );
    useEffect( () => {
        if ( educationPlans.length !== 0 ) {
            setSelectedType( educationPlans[ 0 ].name );
            setServiceId( educationPlans[ 0 ].service_id );
            setConvFee(educationPlans[0].wallet_convinience_fee)
        };
        return ()=> console.log('')
    }, [ educationPlans ] );

    //Update Data Plans to Show
    useEffect( () => {
        if ( selectedType ) {
            const eduPlans = educationPlans.find( d => d.name === selectedType );
            if ( eduPlans !== 0 ) {
                
                setPlans( eduPlans.plans );
                setServiceId( eduPlans.service_id );
                setConvFee(eduPlans.wallet_convinience_fee)
            } else {
                setPlans()
            }
            
        }
        return ()=> console.log('')
    }, [ selectedType ] );

    const updateAmount = ( e ) => {
        const value = e.target.value;
       
        
       
        let amount = plans.find( plan => plan.plan_id === e.currentTarget.selectedOptions[ 0 ].id );
        if ( amount ) {
            setAmount( Utils.formatCurrency( parseInt( amount.price ) * unit ) );
            setplanId( amount.plan_id );
            setAmountToPay( Utils.formatCurrency( parseFloat( amount.price * unit )+ parseFloat( convFee ) )  );
        }
        
        
    }

    useEffect( () => {
        if ( plans && plans.length !== 0 ) {
            setAmount( Utils.formatCurrency( parseInt( plans[ 0 ].price ) ) );
            setplanId( plans[ 0 ].plan_id );
            setAmountToPay( Utils.formatCurrency(  parseFloat( (plans[ 0 ].price ) * unit )+ parseFloat( convFee ) ) );
        } else {
            setAmount( '' );
            setplanId( '' )
        }
    }, [ plans,unit ] );

    const updateUnit = ( sign ) => {
        if ( sign === 'minus' ) {
            // let u = unit;
            if ( unit > 1 ) {
                setUnit( unit - 1 );
            };
        };

        if ( sign === 'plus' ) {
            if ( unit < 5 ) {
                setUnit( unit + 1 );
            };
            
        };
    };

    const orderEducationPayment = () => {
        if ( parseFloat( amountToPay.replace( /₦/g, '' ).replace( /,/g, '' ) ) > parseFloat( user.wallet_balance ) ) {
          
            return setMsg( 'Insufficient funds.' )
        }else{
            if(pin){
                let data ={
                    service_id:serviceId,
                    plan_id:planId,
                    pin,
                    coupon_code:code,
                    quantity:unit
                }

                store.dispatch( buyEducationPayments(data) );
                
            }else{
            
                return setMsg( 'Invalid Pin' );
            };
        };
    };

    const modalLeft = () => {
        return (
            <div className="modal-left">
                <form>
                    <h5>{ msg }</h5>
                    <div>
                        <label>Select Type</label>
                        <select value={ selectedType } onChange={ ( e ) => setSelectedType( e.target.value ) }>
                            { ( educationPlans.length > 0 ) ? educationPlans.map( ( edu ) => (
                                <option key={ edu.id }>{ edu.name }</option>
                            ) ) : <option>Select Type</option> }
                                
                        </select>
                    </div>
                    <div>
                        <label>Select Item</label>
                        <select onChange={ ( e ) => updateAmount( e ) }  >
                       
                            { ( plans ) ? plans.map( p => (
                                <option id={ p.plan_id } plan_id={ p.plan_id } key={ p.plan_id }>{ p.name.toUpperCase() }</option>
                            ) ) : <option>Select Plan</option> }
                        </select>
                        
                    </div>
                    <div>
                        <label>Amount</label>
                        <input disabled={ true } value={ amount } />
                    </div>
                    <div className="units">
                            <label>Units</label>
                            <div>
                                <i className="fa fa-minus" onClick={ () => updateUnit( 'minus' ) }></i>
                                <span>{ unit }</span>
                                <i className="fa fa-plus" onClick={ () => updateUnit( 'plus' ) }></i>
                            </div>
                        </div>
                    <div className="amount">
                        <label>Amount + Convenience Fee({convFee?Utils.formatCurrency(parseFloat(convFee)):null})</label>
                        <input disabled={ true } value={ amountToPay}/>
                    </div>
                    <div className="pin">
                        <label>Transaction Pin</label>
                        <input onChange={ ( e ) => setPin( e.target.value ) } value={ pin } />
                    </div>
                </form>
                <p onClick={()=>orderEducationPayment()} >Order Now</p>
            </div>
        );
    };

     const loadCoupon = () => {
        if ( !code ) {
            return setMsg( 'Coupon code cannot be blank.' )
        }
        let data = {
            coupon_code: code
        }
        setMsg( '' )
        
        store.dispatch( HandleVerifyCoupon( data ) )

    };

    const modalRight = () => {
        return (
            <div className="modal-right">
 { plan ?
                    <>
                        <form>
                            <h5>{ msg }</h5>
                            <div>
                                <label>Type</label>
                                <input disabled={ true } value={ selectedType } />
                            </div>
                            <div>
                                <label>Item</label>
                                <input disabled={ true } value={ plan } />
                            </div>
                            <div>
                                <label>Amount</label>
                                <input disabled={ true } value={ amount } />
                            </div>
                            

                            <div className="pin">
                                <label>Transaction Pin</label>
                                <input onChange={ ( e ) => setPin( e.target.value ) } value={ pin } />
                            </div>
                        </form>
                        <p onClick={ ( e ) =>orderEducationPayment() } >Order Now</p>
                    </> :
                    <>
                        <form>
                            <h5>{ msg }</h5>
                            <div>
                                <div className="coupon-code">
                                    <label>Enter coupon code</label>
                                    <input onChange={ ( e ) => setCode( e.target.value ) } value={ code } />
                                </div>
                            </div>
                        </form>
                        <p onClick={ () => loadCoupon() } >Load</p>
                    </> }
            </div>
        );
    };

    const handleChangeForm = ( { e, className } ) => {
        const modalRight = document.getElementsByClassName( 'modal-right' )
        const modalLeft = document.getElementsByClassName( 'modal-left' )
        setPlan( '' )
        setMsg( '' )
        if ( className === 'right' ) {
            //Remove Active class from left
            e.currentTarget.previousElementSibling.classList.remove( 'left-active' )
            e.currentTarget.classList.add( 'right-active' );
            modalLeft[ 0 ].style.display = 'none';
            modalRight[ 0 ].style.display = 'flex'
        };


        if ( className === 'left' ) {
            //Remove Active class from right
            e.currentTarget.nextElementSibling.classList.remove( 'right-active' )
            e.currentTarget.classList.add( 'left-active' );
            modalLeft[ 0 ].style.display = 'flex';
            modalRight[ 0 ].style.display = 'none'
        };
    };

    return (
        <section className="education">
            <h3>Education</h3>
            <div>
                <div className="header">
                    <h4 onClick={ ( e ) => { handleChangeForm( { e, className: 'left' } ) } } className="left left-active">Use wallet</h4>
                    <h4 onClick={ ( e ) => { handleChangeForm( { e, className: 'right' } ) } } className="right">Use Coupon</h4>
                </div>
                <div className="form">
                    { modalLeft() }
                    { modalRight() }
                </div>
            </div>
        </section>
    );
};

const mapStateToProps =({data:{errorMessage,user},public:{education_variations,server_message}})=>({
errorMessage,user,education_variations,server_message
})

export default connect(mapStateToProps, null)(Education);
