import React, {useState,useEffect} from 'react'
import './Dashboard.css'
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Mainpage from './Mainpage/Mainpage';
import PopUps from '../Popups/Popups'
import {getBalance, authenticateUserFromToken,getAllUsers,getAllTransactions,getAllPayments, getVTBalance } from '../../../actions/Action'
import {connect} from 'react-redux'
import store from '../../../store'
const Dashboard = ({errorMessage,users,transactions,payments,products,user,balance}) => {

    const [ page, setPage ] = useState( 1 );
    const [ pageToShow, setPageToShow ] = useState( '' );
    const [ Tdetails, setTdetails ] = useState();
    const [ userDetails, setUserDetails ] = useState();
    const [ paymentDetails, setPaymentDetails ] = useState();
    const [ dataDetails, setDataDetails ] = useState( '' );
    const [ cable, setCable ] = useState();

    //Load Transactions
    useEffect( () => {
        store.dispatch( authenticateUserFromToken() );
        store.dispatch( getBalance() );
        store.dispatch( getVTBalance() );
        store.dispatch( getAllTransactions() );
        store.dispatch( getAllUsers() );
        store.dispatch(getAllPayments())
        
        
    }, [] );
    return (
        <section className="admin-dashboard">
            <Header User={user} />
            <Sidebar setPage={ setPage } />
            <Mainpage Balance={balance} products={products} payments={payments} Transactions={transactions} users={users} setCable={ setCable } setDataDetails={ setDataDetails } setPaymentDetails={ setPaymentDetails } setPageToShow={ setPageToShow } setUserDetails={ setUserDetails } page={ page } setTdetails={ setTdetails } />
            <PopUps errorMessage={errorMessage} cable={ cable } dataDetails={ dataDetails } paymentDetails={ paymentDetails } pageToShow={ pageToShow } userDetails={ userDetails } Tdetails={ Tdetails } />
        </section>
    )
};


const mapStateToProps = ( { data:{atgBalance, errorMessage,users,allTransactions,payments,products,user,vtBalance} } ) => ( {
    errorMessage,
    users,
    transactions:allTransactions,
    payments,
    products,
    user,
    balance:{
        atgBalance,
        vtBalance
    }
})

export default connect(mapStateToProps,null)(Dashboard)
