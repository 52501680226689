import React, {useState, useEffect} from 'react'
import SideBar from './SideBar/SideBar'
import MainPage from './MainPage/MainPage'
import Header from './Header/Header'
import PopUps from '../PopUps/PopUps'
import {connect} from 'react-redux'
import { HandleGetUser,authenticateUserFromToken } from '../../actions/Action'
import store from '../../store'
import './Dashboard.css'
const Dashboard = ({isAdmin}) => {

    const [ page, setPage ] = useState( 1 );
    const [ pageToShow, setPageToShow ] = useState( '' );
    const [ Tdetails, setTdetails ] = useState();

    //Load Transactions
    useEffect( () => {
        
        store.dispatch( HandleGetUser() );
        store.dispatch( authenticateUserFromToken() );

        return ()=>console.log('')
    },[] );

    return (
        <div className="dashboard">
            <Header/>
            <SideBar setPage={setPage} isAdmin={isAdmin} />
            <MainPage setPageToShow={setPageToShow} page={ page } setTdetails={setTdetails} />
            <PopUps setPageToShow={setPageToShow}  pageToShow={ pageToShow } Tdetails={ Tdetails}/>
        </div>
    )
}
const mapStateToProps = ( { data:{isAdmin} } ) => ( {
    isAdmin
})

export default connect(mapStateToProps,null)(Dashboard)
