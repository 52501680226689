import React from 'react'
import './TransactionsDetails.css';
import './mobile.css';
import Utils from '../../Utils/Functions'
const TransactionsDetails = ({Tdetails,setPageToShow}) => {


    const formatDate = (date) => {
        let hours = new Date( parseInt(date) ).getHours() % 12;//To Get Local hours
        const minutes = new Date( parseInt(date) ).getMinutes();
        let q = 'PM'

        const months = [ 'January', 'February', 'March', 'April', 'May',
            'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        
        const days =['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        let Nday = new Date( parseInt(date) ).getDate();
        let day = days[ new Date( parseInt( date ) ).getDay()];
        let month = months[new Date( parseInt( date ) ).getMonth()];
        let year = new Date( parseInt( date ) ).getFullYear();

        if ( new Date( ( parseInt( date ) ) ).getHours() < 12 ) {
            let q = 'AM'
        }
        if ( Nday < 10 ) {
            Nday = '0' + Nday
        }
        if ( hours === 0 ) {
            hours = '12';
        }
        const d = {
            time: `${ new Date(parseInt( date ) ).toLocaleString()}`,
               
            date: ` ${ day }  ${Nday} ${ month }, ${ year }`
         
            
        }

        return d
    }

    const close = ( id ) => {
        Utils.closePopUp();
        setPageToShow('')
    }
    return (
        <div className="tdetails slowly-show-popups">
            <div className="right">
                <p className="show-on-mobile">Id: { Tdetails.transaction_id}</p>
                <p>Time: { formatDate(Tdetails.date).time}</p>
                <p>Date: { formatDate(Tdetails.date).date}</p>
                { ( Tdetails.product === 'Airtime' || Tdetails.product === 'Data' ) ?
                <p>Phone No: { Tdetails.transaction_phone }</p>
                : ( Tdetails.product === 'Electricity' ) ?
            <p>Meter No: {Tdetails.meter_number ? Tdetails.meter_number: Tdetails.customerData.Meter_Number?Tdetails.customerData.Meter_Number : null}</p>
            : ( Tdetails.product === 'Education' && Tdetails.quantity ) ? <p>Quantity: {Tdetails.quantity}</p>
             : ( Tdetails.product === 'CableTV' ) ?
            <p>Smart Card Number No: {Tdetails.smart_card_number}</p>
                        : null
        }
        <p>Balance Before: { Utils.formatCurrency(parseFloat(Tdetails.balance_before))}</p>
        <p>Balance After: { Utils.formatCurrency(parseFloat(Tdetails.balance_after))}</p>
                { Tdetails.plan ? <p>Plan: {Tdetails.plan }</p>: null}
                { Tdetails.customerName ? <p>Customer: {Tdetails.customerName }</p>: null}
                { Tdetails.account_id ? <p>Account ID: {Tdetails.account_id }</p>: null}
                { Tdetails.amountCharged ? <p>Amount-Charged: {Utils.formatCurrency(parseFloat(Tdetails.amountCharged)) }</p>: null}
                { Tdetails.token ? <p>Token: {Tdetails.token }</p>: null}
                { Tdetails.exchangeReference ? <p>E-REF: {Tdetails.exchangeReference }</p>: null}
                { Tdetails.units ? <p>Units: {Tdetails.units }</p>: null}
            </div>
            
            <div className="left">
                <p onClick={()=>close()}>Close</p>
                
            </div>
        </div>
    )
}

export default TransactionsDetails
