import React, { useEffect } from 'react';
import './Landing.css';
import './mobile.css';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { connect } from 'react-redux';
import Footer from '../Footer/Footer';
const Landing = () => {
  useEffect(() => {
    getstarted();
    aboutUs();
    whyUs();
    services();

    return () => {
      console.log('');
    };
  }, []);

  const getstarted = () => {
    const elInner = document.querySelector('.getstarted-inner');

    let observer = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-content 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observer.observe(elInner);
  };

  const aboutUs = () => {
    const elInner = document.querySelector('.about-us-inner');
    const elImg = document.querySelector('.about-us-img');

    let observeInner = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-content 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeInner.observe(elInner);

    let observeImg = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-about-us-img 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeImg.observe(elImg);
  };

  const whyUs = () => {
    const elInner = document.querySelector('.why-us-inner');
    const elImg = document.querySelector('.why-us-img');

    let observeInner = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-content 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeInner.observe(elInner);

    let observeImg = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-about-us-img 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeImg.observe(elImg);
  };

  const services = () => {
    const elInner = document.querySelector('.services-inner');
    const elImg = document.querySelector('.services-img');
    let observeInner = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-content 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeInner.observe(elInner);

    let observeImg = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `animate-about-us-img 1s forwards ease-out`;
      } else {
        entries[0].target.style.animation = 'none';
      }
    });
    observeImg.observe(elImg);
  };

  const showMobileNav = () => {
    const mobileNav = document.getElementsByClassName('mobile-navigation');
    const landingPage = document.getElementsByClassName('landing-page');
    if (landingPage[0].classList.contains('no-overflow')) {
      landingPage[0].classList.remove('no-overflow');
    } else {
      landingPage[0].classList.add('no-overflow');
    }
    if (mobileNav[0].classList.contains('mobile-navigation-show')) {
      mobileNav[0].classList.remove('mobile-navigation-show');
    } else {
      mobileNav[0].classList.add('mobile-navigation-show');
    }
  };

  return (
    <section className='landing-page'>
      <div className='navbar'>
        <header>
          <div className='app-logo'>
            <a href='https://creditsync.com.ng'>
              <img
                className='site-logo'
                src='../../images/logo-website-home-header.png'
                alt='No.1 Data, Airtime, Cabletv giveaway platform - CreditSync'
              />
            </a>
          </div>

          <nav className='mobile-hide'>
            <a>
              <Link to='get-started' smooth={true} duration={1000}>
                Home
              </Link>
            </a>

            <Link to='about-us' smooth={true} duration={1000}>
              About Us
            </Link>
            <Link to='why-us' smooth={true} duration={1000}>
              Why Us{' '}
            </Link>
            {/* <NavLink to="/quickbuy">Quick Buy</NavLink> */}
            <NavLink to='/frequently-asked-questions'>FaQs</NavLink>
            <NavLink to='/privacy-policy'>Privacy</NavLink>
            {/* <NavLink to="/auth/login">Login</NavLink> */}
          </nav>
          <i className='fa fa-bars' onClick={() => showMobileNav()}></i>
        </header>
      </div>
      <div className='mobile-navigation'>
        <a>
          <Link
            to='get-started'
            onClick={() => showMobileNav()}
            smooth={true}
            duration={1000}
          >
            Home
          </Link>
        </a>
        <Link
          to='about-us'
          onClick={() => showMobileNav()}
          smooth={true}
          duration={1000}
        >
          About Us
        </Link>
        <Link
          to='why-us'
          onClick={() => showMobileNav()}
          smooth={true}
          duration={1000}
        >
          Why Us{' '}
        </Link>
        {/* <NavLink to="/quickbuy">Quick Buy</NavLink> */}
        <NavLink to='/frequently-asked-questions'>FaQs</NavLink>
        <NavLink to='/privacy-policy'>Privacy</NavLink>
        {/* <NavLink to='/auth/login'>Login</NavLink> */}
      </div>
      <div id='get-started' className='get-started'>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/c_scale,w_1437/v1606016777/get-started_ctrcje.jpg'
          alt='No.1 Data, Airtime, Cabletv giveaway platform - CreditSync'
          height='100%'
          width='100%'
          className=''
        />

        <div>
          <div className='getstarted-inner'>
            <h1>Recharging just got alot more easier.</h1>
            <h3>
              CreditSync is a platform for purchasing your Cable TV
              subscriptions,Data subscriptions,utility bill payment and Airtime
              seamleassly in an automated way, why not give us a trial today.
            </h3>

            <a
              href='https://play.google.com/store/apps/details?id=com.creditsync'
              target='_blank'
              className='getstarted-inner'
            >
              GET STARTED
            </a>
          </div>
        </div>
      </div>
      <div id='about-us' className='about-us'>
        <div className='about-us-inner'>
          <h1 className='about-us-inner'>
            <span className='color-1'>Welcome</span> to{' '}
            <span className='color-2'>CreditSync</span>
          </h1>

          <p className='about-us-inner'>
            CreditSync is an internet powered vending platform that enables
            users purchase virtual products and services such as mobile data,
            cableTV subscriptions,airtime and utility bills payment, waec result
            checkers.<br></br>
            <br></br>
            With creditsync you can gift friends, family or instagram/twitter
            followers data, airtime e.t.c without requesting for their mobile
            numbers.
          </p>

          <a
            href='https://play.google.com/store/apps/details?id=com.creditsync'
            target='_blank'
            className='about-us-inner'
          >
            DOWNLOAD NOW
          </a>
        </div>

        <div id='img' className='about-us-img'>
          <img
            src='https://res.cloudinary.com/seyi-js/image/upload/v1606016773/about-us_mdlini.jpg'
            alt='No.1 Data, Airtime, Cabletv giveaway platform - CreditSync'
            className='slide-from-right'
          />
        </div>
      </div>
      <div className='why-us' id='why-us'>
        <div className='left why-us-img'>
          <img
            src='https://res.cloudinary.com/seyi-js/image/upload/v1606016774/why-us_t7slox.jpg'
            alt='No.1 Data, Airtime, Cabletv giveaway platform - CreditSync'
            className='slide-from-us-right'
          />
        </div>
        <div className='right why-us-inner'>
          <h1 className=''>Why CreditSync ?</h1>
          <div>
            <div className='why-us-inner'>
              <i className='fa fa-check-circle'></i>
              <b>It is Cheap.</b>
            </div>
            <div className='why-us-inner'>
              <i className='fa fa-check-circle'></i>

              <b>We are Reliable.</b>
            </div>
            <div className='why-us-inner'>
              <i className='fa fa-check-circle'></i>

              <b>Fully automated.</b>
            </div>
            <div className='why-us-inner'>
              <i className='fa fa-check-circle'></i>

              <b>Security is guaranteed.</b>
            </div>
            <div className='why-us-inner'>
              <i className='fa fa-check-circle'></i>

              <b>24x7 Availability.</b>
            </div>
          </div>
        </div>
      </div>
      <div className='services' id='services'>
        <div className='left services-inner'>
          <h1 className=''>Services</h1>
          <div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>Data top-up.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>Airtime purchase.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>Electricity Bill payments.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>CableTV payments.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>WAEC Reg Pins.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>WAEC Result Checker.</b>
            </div>
            <div className='services-inner'>
              <i className='fa fa-check-circle'></i>
              <b>Automated giveaway with coupons.</b>
            </div>
          </div>
        </div>
        <div className='right services-img'>
          <img
            src='https://res.cloudinary.com/seyi-js/image/upload/c_scale,w_636/v1610667531/sigmund-xLZ9EP4hJtQ-unsplash_kxcruy.jpg'
            alt='No.1 Data, Airtime, Cabletv giveaway platform - CreditSync'
            className='slide-from-us-left'
          />
        </div>
      </div>

      <div className='partners'>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671132/mtn-icon-150-by-150_wktene.png'
          alt='MTN'
        />
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671173/glo-icon-150-by-150_qpbjw9.png'
          alt='GLO'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671198/etisalat-icon-150-by-150_s1rclw.png'
          alt='Etisalat'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671263/Airtel-icon-150-by-150_orhzdw.png'
          alt='Airtel'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671592/kyxqid8uwo1lb6mb1hsk.jpg'
          alt='DSTV'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671292/dstv_jiscdt.jpg'
          alt='DSTV'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671318/gotv_kudnqe.jpg'
          alt='GOTV'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671337/ie_d3y55c.png'
          alt='IKEJA ELECTRIC'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671358/smile_gnwnlj.png'
          alt='SMILE'
        ></img>
        <img
          src='https://res.cloudinary.com/seyi-js/image/upload/v1610671384/spectranet_gijbpp.png'
          alt='SPECTRANET'
        ></img>
      </div>

      <Footer />
    </section>
  );
};

export default Landing;
