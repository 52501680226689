import React,{useEffect,useState} from 'react'
import './Transactions.css';
import './mobile.css';
import Utils from '../../../Utils/Functions';
const Transactions = ({setPageToShow,setTdetails,Transactions}) => {
    const [ sorted, setSorted ] = useState( [] );
    const [order,setOrder] =useState('Default');
    const [ filtered, setFiltered ] = useState( [] );



    useEffect( () => {
        setFiltered(Transactions)
    },[Transactions])

    useEffect( () => {
        if ( Transactions.length !== 0 ) {
            const compare =(a, b) =>{
                
                //This will only Be in Descending order
                const A = a.date;
                const B = b.date;
             
                let comparison = 0;
                //To Print in ascending order change this to >
                if (A < B) {
                    comparison = 1;
                    
                } else{
                  comparison = -1;
                }
                
                return comparison;
            }
            setSorted(filtered.sort(compare))
            
           }
       },[filtered])

    useEffect( () => {
        if ( order === 'Default' ) {
            setFiltered(Transactions)
        } else {
        setFiltered( Transactions.filter( transaction => transaction.status === order.toLowerCase() ) );
            
        }
  },[order])

    const setStatusBackground = (transaction) => {
        if ( transaction.status === 'failed' ) {
            return { background: "#f85d5d" };
        } else if (transaction.status === 'reversed') {
            return {background:"#4a7111"}
        }else if(transaction.status === 'pending'){
            return {background:'#707471'}
        } else if(transaction.status === 'cancelled'){
            return {background:'#f85d5d'}
        } 
        else {
            return { background: "#5df888" };
    }
}

    
    const formatDate = (date) => {
        const hours = new Date( parseInt(date) ).getHours() % 12;//To Get Local hours
        const minutes = new Date( parseInt(date) ).getMinutes();
        let month = new Date( parseInt(date) ).getMonth() +1;
        let day = new Date( parseInt(date) ).getDate();
        const year = new Date( parseInt( date ) ).getFullYear();
        
        if ( day < 10 ) {
            day = '0' + day
        }

        if ( month < 10 ) {
            month = '0' + month
        }

        return `${day} - ${month} - ${year}`
    }


    const Open = (data) => {
        setPageToShow( 'TDetails' );
        setTdetails(data)
        Utils.showPopUp();
    }

    const tableData = (transaction) => {
        return (
             <div key={transaction.transaction_id} className="table-data" onClick={()=>Open(transaction)}>
                     <ul>
                        <li>{formatDate(transaction.date)}</li>
                        <li className="hide-on-mobile">{transaction.transaction_id}</li>
                        <li> {transaction.product}</li>
                        <li>{Utils.formatCurrency(parseInt(transaction.value))}</li>
                        <li style={setStatusBackground(transaction)} className="status">{transaction.status}</li>
                    </ul>
            </div>
        )
    }




    return (
        <div className="transactions">
            
            <div className="header">
            <h3>Transactions</h3>
                <div>
                    <select onChange={(e)=>setOrder(e.target.value)}>
                        <option>Default</option>
                        <option>Reversed</option>
                        <option>Successful</option>
                        <option>Failed</option>
                    </select>
                </div>
                
            </div>
        <div className="table">
            <div className="table-label">
                <ul>
                    <li>Date</li>
                    <li className="hide-on-mobile">Transaction id</li>
                    <li>Product</li>
                    <li>Value</li>
                    <li>Status</li>
                </ul>
            </div>
                { ( sorted ) ?
                    sorted.map( sort => (
                        tableData(sort)
                    ))
                
                
             : null }
        </div>
        </div>
    )
}

export default Transactions
