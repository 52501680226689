import React,{useState, useEffect} from 'react'
import './SignUp.css';
import './mobile.css';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom'
import Qs from 'query-string';
import { HandleRegistration,userLoad } from '../../../actions/Action';
import store from '../../../store';
import {REGISTER_FAIL,REGISTER_SUCCESS} from '../../../actions/types'

const SignUp = ( { data } ) => {
    const [ type, setType ] = useState( 'password' );
    const [ password, setPassword ] = useState( '' );
    const [ confirmPassword, setConfirmPassword ] = useState( '' );
    const [ name, setName ] = useState( '' );
    const [ msg, setMsg ] = useState( '' );
    const [ email, setEmail ] = useState( '' );
    const [ phone, setPhone ] = useState( '' )
    const { errorMessage } = data;
    const [ showMessage, setShowMessge ] = useState( false );
    const [ referralCode, setReferralCode ] = useState( '' )
    
    useEffect( () => {
        if ( errorMessage && errorMessage.type === REGISTER_FAIL ) {
            setMsg( errorMessage.msg );
            // Utils.closePopUp()
        } else if ( errorMessage && errorMessage.type === REGISTER_SUCCESS ) {
            setMsg( 'A verification link has been sent to your email it will expire in 24hours,please check your spam folder if not found.' )
            setShowMessge( true );
        }
         
        return ()=>{
            console.log('')
        }
    }, [ errorMessage ] );

    useEffect( () => {
        const { referral_code } = Qs.parse( window.location.search )
        if ( referral_code ) {
            setReferralCode( referral_code )
            // history.push(`/auth/signup?referralcode=${referral_code}`)
        }
        
         
    }, [] );

    const showPassword = ( e ) => {
        setType( 'text' );
        const faEye = e.currentTarget;
        //Hide Fa Eye
        faEye.style.display = "none"

        //Show Fa Eye Slash
        const faEyeSlash = e.currentTarget.nextSibling;
        faEyeSlash.style.display = "block"
    };


    const hidePassword = ( e ) => {
        setType( 'password' );
        const faEye = e.currentTarget.previousSibling;
        //Show Fa Eye
        faEye.style.display = "block"

        //Hide Fa Eye Slash
        const faEyeSlash = e.currentTarget;
        faEyeSlash.style.display = "none"
    };

    
    
    
    
    const signUp = () => {
        const checkName = name.match( /[A-Za-z]{5,}/g );
        const passwordlength = password.match( /[A-Z0-9a-z]{8,}/g )
        let firstThree = phone.slice( 0, 3 );

        const numberArray = [ '070', '081', '080', '090', '091' ];
        const indexOfFirstThreeNumber = numberArray.indexOf( firstThree );
        const lenthOfPhoneNumber = phone.length === 11;
        const space = phone.match( /\s/g );
        const non_digit = phone.match( /\D/g );
        const alpha = phone.match( /[a-zA-Z]/g );
        const checkEmail = email.match( /^[^\s@]+@[^\s@]+\.[^\s@]+$/ )
        if ( !checkName ) {
            return setMsg( 'Name must be more than 5 characters.' )
        }

        if ( indexOfFirstThreeNumber === -1 || !lenthOfPhoneNumber || space || non_digit || alpha ) {
            return setMsg( 'Invalid Phone number.' )
        }

        if ( !passwordlength ) {
            return setMsg( 'Password too short.' )
        }

        if ( password !== confirmPassword ) {
            return setMsg( 'Passwords not equal' )
        }

        if ( !checkEmail ) {
            return setMsg( 'Invalid email' )
        }

        setMsg( '' );
        const data = {
            name,
            password,
            email,
            phone,
            referral_code: referralCode
        }
        // store.dispatch( userLoad() )
        store.dispatch( HandleRegistration( data ) )
    }

    return (
        <section className="sign-up-page">
            
            <div>
                { !showMessage ? <>
                    <h1>Register</h1>
                    <h3>{ msg }</h3>
                    <form>
                        <div>
                            <i className="fa fa-user"></i>
                            <input onChange={ e => setName( e.target.value ) } type="text" placeholder="Your name" />
                        </div>
                        <div>
                            <i className="fa fa-envelope"></i>
                            <input onChange={ e => setEmail( e.target.value ) } type="email" placeholder="Email" />
                        </div>
                        <div>
                            <i className="fa fa-phone"></i>
                            <input onChange={ e => setPhone( e.target.value ) } type="Tel" placeholder="Telephone e.g 09012345678" />
                        </div>
                        <div className="password">
                            <i className="fa fa-lock"></i>
                            <input onChange={ e => setPassword( e.target.value ) } type={ type } placeholder="Password" />
                            <i className="fa fa-eye eye" onClick={ ( e ) => showPassword( e ) } />
                            <i className="fa fa-eye-slash eye" onClick={ ( e ) => hidePassword( e ) } />
                            
                            
                        </div>
                        <h6><i className="fa fa-info-circle"></i> password must be atleast 8 characters.</h6>
                        <div>
                            <i className="fa fa-check"></i>
                            <input onChange={ e => setConfirmPassword( e.target.value ) } type={ type } placeholder="Confirm password" />
                        </div>
                        
                    </form>
                    <p onClick={ () => signUp() }>Signup</p>
                    <h6 className="sign-in">Have an account with us? <NavLink to="/auth/login"> Login.</NavLink></h6>
                </> : <>
                        <h1>Thanks for joining the family.</h1>
                        <h3>{ msg }</h3>
                    </> }

            </div>
           
        </section>
    );
};

const mapStateToProps = ( { data } ) => ( {
    data
} );
export default connect( mapStateToProps, null )( SignUp );
