import React,{useState,useEffect} from 'react'
import './CableTV.css'
import './mobile.css'
import Utils from '../../../../Utils/Functions'
import { connect } from 'react-redux'
const CableTV = ( { setCable, setPageToShow ,cabletv_variations} ) => {
    const [ plans, setPlans ] = useState();
    const [ amount, setAmount ] = useState();
    const [ planName, setPlanName ] = useState( '' );
    const [tvs,setTvs] =useState([])
    

    const [ selectedTV, setSelectedTV ] = useState( );

    useEffect( () => {
        setTvs( cabletv_variations )
    }, [ cabletv_variations] );


    useEffect( () => {
        if (tvs.length !== 0 && tvs[ 0 ].name) {
            setSelectedTV(tvs[ 0 ].name )
       }
    },[tvs])

    const editTv = (data) => {
        setPageToShow( 'cabletvdetails' );

        let d = {
            data,
            selectedTV
        }
        setCable( d );
        Utils.showAdminPopUp()
    }

    const TVS = ( data ) => {
                
        return (
            <div className="floating-inputs" key={data.id}>
                <div className="inputs" onClick={()=>editTv(data)} >
                    <input disabled  id={data.id} className="name" placeholder="Name" value={data.name} />
                    <input disabled  id={data.id}   className="price" placeholder="Price"  value={data.price}  />
                </div>
                
            </div>
        )
    };

    const newTvs =()=>{
        return (
            <div className="floating-inputs" >
                <div className="inputs"  >
                    <input onChange={(e)=> setPlanName(e.target.value)} className="name" placeholder="Name" />
                    <input onChange={(e)=> setAmount(e.target.value)}  className="price" placeholder="Price"   />
                </div>
                <div className="options">
                    <p>Add</p>
                </div>
            </div>
       )
    }
    

     //Update Cable Plans to Show
     useEffect( () => {
        if ( selectedTV ) {
            const tvPlans = tvs.filter( d => d.name === selectedTV );
            if ( tvPlans.length !==0 ) {
                
                setPlans(tvPlans[ 0 ].plans)
                
            } else {
                setPlans()
            }
            
        }
    },[selectedTV])


    return (
        <section className="cable-tv-admin">
            <h3>Update CableTV Subcriptions</h3>
            <div>
                <form>
                    <div>
                    <label>Select TV</label>
                    <select onChange={e=>setSelectedTV(e.target.value)}>
                        { ( tvs.length > 0 ) ? 
                            tvs.map( tv => (
                                <option key={tv.id}>{tv.name}</option>
                            ))
                            : <option>select TV</option> }
                    </select>
                    </div>
                    { ( plans ) ? plans.map( p => (
                        TVS(p)
                    ) ) : null }
                    
                </form>
            </div>
        </section>
    )
};

const mapStateToProps =({public:{cabletv_variations}})=>({
cabletv_variations
})

export default connect(mapStateToProps, null)(CableTV)
