import React from 'react'
import './TotalNumberOfUsers.css'
const TotalNumberOfUsers = ({Users}) => {
    return (
        <div className="total-number-of-users">
            <div className="value">
                <p>{Users.length }</p>
            </div>
            <p>Total Number <br></br>Of Users</p>
        </div>
    )
}

export default TotalNumberOfUsers
