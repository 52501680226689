import React, {useState,useEffect} from 'react'
import './Login.css';
import './mobile.css';
import {NavLink} from 'react-router-dom'
import store from '../../../store';

import { HandleLogin } from '../../../actions/Action';
import { connect } from 'react-redux';
import Utils from '../../Utils/Functions'
import { LOGIN_FAIL } from '../../../actions/types';
const Login = ( { data }, props ) => {
    const [ type, setType ] = useState( 'password' );
    const [ password, setPassword ] = useState( '' );
    const [ username, setUsername ] = useState( '' );
    const [ msg, setMsg ] = useState( '' );
    
    const { errorMessage } = data;

    useEffect( () => {
        if ( errorMessage && errorMessage.type === LOGIN_FAIL) {
            setMsg( errorMessage.msg );
            Utils.closePopUp()
        }
         
        // else {
        //     history.push('/auth/login')
            
        // }

        return ()=> console.log('')
    }, [ errorMessage ] );

   



    const showPassword = ( e ) => {
        setType( 'text' );
        const faEye = e.currentTarget;
        //Hide Fa Eye
        faEye.style.display = "none"

        //Show Fa Eye Slash
        const faEyeSlash = e.currentTarget.nextSibling;
        faEyeSlash.style.display = "block"
    };


    const hidePassword = ( e ) => {
        setType( 'password' );
        const faEye = e.currentTarget.previousSibling;
        //Show Fa Eye
        faEye.style.display = "block"

        //Hide Fa Eye Slash
        const faEyeSlash = e.currentTarget;
        faEyeSlash.style.display = "none"
    };

    const loginNow = () => {
        const checkEmail = username.match( /^[^\s@]+@[^\s@]+\.[^\s@]+$/ )
        if ( !password | !username ) {
            return setMsg( 'Please fill in your details.' );
        }
        
        if ( !checkEmail ) {
            return setMsg( 'Please enter a vaild email address.' )
        }

        setMsg( '' );
        store.dispatch( HandleLogin( { username, password } ) )
        
    }


    return (
        <section className="login-page">
            
            
            <div>
                <h1>Login</h1>
                <h3>{ msg }</h3>
                <form>
                    <div>
                        <i className="fa fa-envelope"></i>
                        <input onChange={ ( e ) => setUsername( e.target.value ) } type="email" placeholder="Email" />
                    </div>
                    <div>
                        <i className="fa fa-lock"></i>
                        <input onChange={ e => setPassword( e.target.value ) } type={ type } placeholder="Password" />
                        <i className="fa fa-eye eye" onClick={ ( e ) => showPassword( e ) } />
                        <i className="fa fa-eye-slash eye" onClick={ ( e ) => hidePassword( e ) } />
                    </div>
                </form>
                <h6>Forgot password<NavLink to="/auth/forgotpassword"> here.</NavLink></h6>
                <p onClick={ () => loginNow() } >Login</p>
                <h6 className="sign-up">Don't have an account? <NavLink to="/auth/signup"> Sign-up.</NavLink></h6>
            </div>
            
        </section>
    );
};

const mapStateToProps = ( { data } ) => ( {
    data
} );

export default connect( mapStateToProps, null )( Login );
